import { useParams } from 'react-router-dom';
import * as labels from "../components/localized/labels";
import * as api from '../api/apiConnector';
import { useNavigate } from 'react-router-dom';
import { React, useState, useEffect, useContext } from 'react';
import UserContext from '../components/User_Context';
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import * as utils from "../libs/utils.js";
import annaLoading from "../assets/img/QuickCard/Loading.png";
import annaNotFound from "../assets/img/QuickCard/notFound.png";



function QuickCard_Screen() {
    const navigate = useNavigate();

    //retrieve user from user context
    let { user } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;

    //states
    const [cards, setCards] = useState(null);
    const [document, setDocument] = useState(null);
    const [classroom, setClassroom] = useState(null);
    const [cardsRefresh, setCardsRefresh] = useState(null);




    //params
    const { id, topic, folderId, professorclassroomId, classroomId, language } = useParams();

    const getPicture = async (topic) => {
        var imageData = (await api.sendMessage("getCardPicture", {
            body: {
                topicId: topic._id
            },
            user: user,
        }));

        if (imageData?.success) {
            //console.log(`after OK getImage for topic: ${topic.topic}`);
            return imageData;
        }
        else {

            //console.log(`after KO getImage for topic: ${topic.topic}`);
            return null;
        }
    }

    //use Effect
    useEffect(() => {
        (async () => {
            getData();
        })();
    }, []);

    useEffect(() => {
        //console.log(`cards refresh ${cards?.length}`);
        if (cards && cards.length > 0 && cards[0].topics && cards[0].topics.length > 0) {

            (async () => {
                if (!cards[0].topics || cards[0].topics.length == 0) {
                    setTimeout(() => {
                        //console.log("refreshed");
                        getCards();
                        setCardsRefresh(new Date());
                    }, 2000);
                } else {
                    refreshImageData();
                }
            })();
        }
    }, [cards, cardsRefresh]);



    const refreshImageData = async () => {
        var _mustRefresh = true;
        console.log(`refreshImageData`);

        while (_mustRefresh) {
            var _cards = cards[0].topics.filter(x => !x.picture_processed) || [];
            console.log(`must refresh ${_cards.length} cards`);

            if (_cards.length > 0) {
                for (var card of _cards) {
                    var data = await getPicture(card);
                    card.imageData = data?.imageData;
                    card.resume = data?.resume;
                    card.picture_processed = data != null;

                    var img = window.$(`img[data-topic=${card._id}]`);
                    var figureCaption = window.$(`figcaption[data-topic=${card._id}]`);
                    var button = window.$(`button[data-topic=${card._id}]`);

                    if (data) {
                        figureCaption.text(card.topic);
                        button.css("visibility", 'visible');
                    }

                    if (card.imageData) {
                        img.attr("src", `data:image/png;base64, ${card.imageData}`);
                        img.css("visibility", 'visible');
                    }
                    else if (data) {
                        img.attr("src", `${annaNotFound}`);
                        img.css("visibility", 'visible');
                    }

                    // var width = img.prop('naturalWidth');
                    // var height = img.prop('naturalHeight');
                    //console.log(`image natural size is: ${width} x ${height}`);

                    if (card.resume) {
                        var _text = window.$("<div/>").html(card.resume).text();
                        if (_text.length > 150) _text = `${_text.substring(0, 100)}...`;
                        window.$(`[data-topic-resume=${card._id}]`).text(_text);
                    }
                }
            } else {
                _mustRefresh = false;
            }

            await utils.waitMs(5000);
        }
    }

    const getCards = async () => {
        var mustRefresh = true;

        while (mustRefresh) {
            var cards = (await api.sendMessage("getCards", {
                body: {
                    docId: id,
                    folderId: folderId,
                    classroom: professorclassroomId || classroomId
                },
                user: user,
            }))?.cards;

            if (cards && cards.length > 0 && cards[0].topics && cards[0].topics.length > 0) {
                setCards(cards);
                mustRefresh = false;
                return cards;
            }
            else {
                await utils.waitMs(5000);
            }
        }

    }

    const getData = async () => {
        var doc = (await api.sendMessage("getDoc", {
            body: {
                id: id,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).document;

        if (!doc) {
            doc = (await api.sendMessage("getFolder", {
                body: {
                    id: folderId,
                    classroom: professorclassroomId || classroomId
                },
                user: user,
            })).folder;
        }


        if (!doc) {
            navigate(`/${language}/`);
        }
        else {
            if (doc) {
                setDocument(doc);
            }


        }

        await getCards();

        return;
    }

    const drawCard = (card) => {
        const _imgFront = () => {
            if (card.imageData) {
                return (<img data-topic={card._id} src={`data:image/png;base64, ${card.imageData}`} alt={card.topic} style={{ height: "100%" }} />);
            }
            else {
                if (card?.status == "done" && !card.imageData) {
                    return (<img data-topic={card._id} src={annaNotFound} alt={card.topic} style={{ height: "100%" }} />);

                } else {
                    return (<img data-topic={card._id} src={annaLoading} alt={card.topic} style={{ height: "100%" }} />);
                }
            }
        }

        const _imgBack = () => {
            if (card.imageData) {

                return (
                    <img data-topic={card._id} src={`data:image/png;base64, ${card.imageData}`} alt={card.topic} style={{ width: "100%" }} />
                );

            }
            else {
                return (
                    <>
                        <img data-topic={card._id} src={annaLoading} alt={card.topic} style={{ width: "100%" }} />
                        <p data-topic-resume={card._id} style={{ padding: "1em" }}>{card.resume}</p>
                        <p style={{ padding: "1em", marginTop: "-2em" }}>
                            <button data-topic={card._id} style={{ visibility: "hidden" }}
                                onClick={() => {
                                    showCardDetails(card);
                                }}
                                className="btn btn-link">{labels.getText()["TELL_ME_MORE"]()}</button>
                        </p>

                    </>
                );
            }
        }

        return (
            <div className="flip-card-container col" style={{ "--hue": 220 }}>
                <div className="flip-card">
                    <div className="card-front">
                        <figure>
                            <div className="img-bg"></div>
                            {_imgFront()}
                            <figcaption data-topic={card._id}>LOADING</figcaption>
                        </figure>
                    </div>
                    <div class="card-back">
                        <figure>
                            <div className="img-bg"></div>
                            {_imgBack()}
                        </figure>
                    </div>
                </div>
            </div>);
    }

    const showCardDetails = (card) => {
        window.$("#cardModalTitle").text(card.topic);

        let _body = window.$("#cardBody");

        _body.html(card.resume);
        let _picture = window.$(`<img class="img-fluid" />`);
        if (card?.imageData) {
            _picture.attr("src", `data:image/png;base64, ${card.imageData}`);

        } else {
            _picture.attr("src", `${card?.imageData ?? annaNotFound}`);

        }
        _body.prepend(_picture);
        _body.find("h1, h2, h3").addClass("document-boost title-quick-cards");
        window.$("#cardModal").modal("show");

        let tLinks = _body.find(`a[data-concept]`).toArray();
        for (var tLink of tLinks) {
            tLink = window.$(tLink);
            tLink.attr("target", "_blank");
            const q = `${tLink.attr("data-concept")} > ${tLink.attr("data-topic")}`;
            if (id) {
                tLink.attr("href", `/${language}/qa/${id}/${encodeURI(q)}`);
            } else if (folderId) {
                tLink.attr("href", window.location.href = `/${language}/folder/${folderId}/qa/${encodeURI(q)}`);
            }
        }
    }

    const drawButtons = (params) => {



        if (user.isAdmin) {
            if (cards[0].isPublic) {
                return (

                    <div style={{ textAlign: "right" }}>
                        <button type="button" class="btn btn-link"
                            onClick={async (e) => {
                                await api.sendMessage("publishCards", {
                                    body: {
                                        id: cards[0]._id,
                                        isPublic: false
                                    },
                                    user: user,
                                });

                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);

                            }}
                        >Make private</button>
                    </div>
                )
            }
            else {
                return (
                    <div style={{ textAlign: "right" }}>

                        <button type="button" class="btn btn-link"
                            onClick={async (e) => {
                                await api.sendMessage("publishCards", {
                                    body: {
                                        id: cards[0]._id,
                                        isPublic: true
                                    },
                                    user: user,
                                });

                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                            }}
                        >Publish</button>
                        |
                        <button type="button" class="btn btn-link"
                            onClick={async (e) => {
                                const result = await api.sendMessage("refreshPublicCardsPicture", {
                                    body: {
                                        id: cards[0]._id
                                    },
                                    user: user,
                                });

                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                            }}
                        >Refresh pictures</button>
                        |
                        <button type="button" class="btn btn-link"
                            onClick={async (e) => {
                                const result = await api.sendMessage("getPublicCards_allLanguages", {
                                    body: {
                                        id: id ?? folderId
                                    },
                                    user: user,
                                });

                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                            }}
                        >Generate all languages</button>
                        |
                        <button type="button" class="btn btn-link"
                            onClick={async (e) => {
                                const result = await api.sendMessage("refreshCards", {
                                    body: {
                                        docId: id,
                                        folderId: folderId,
                                        classroom: professorclassroomId || classroomId
                                    },
                                    user: user,
                                });

                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                            }}
                        >Generate again</button>
                    </div>
                );
            }
        }
        else {
            return (null);
        }
    }

    const drawCards = () => {
        return (
            <>
                <div className='row'>
                    {cards[0].topics.map((c, index) => { return drawCard(c) })}
                </div>
            </>
        );
    }

    const drawModal = () => {
        return (<div className="modal fade" id="cardModal" tabindex="-1" aria-labelledby="cardModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="cardModalTitle"></h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body small modal-quick-cards" id="cardBody">

                    </div>
                </div>
            </div>
        </div>);
    }

    const drawMain = () => {
        if (cards && cards.length > 0 && cards[0].topics && cards[0].topics.length > 0) {
            return (<>
                {SetMenu({ user: user })}
                <div className="stack-title">

                    {id ?
                        (getBreadcrumb({ page: "quickcards", id: document._id, name: document.fileName, classroomId, professorclassroomId })) :
                        (getBreadcrumb({ page: "quickcards_folder", id: folderId, folderId: folderId, name: document.name, classroomId: classroomId, professorclassroomId: professorclassroomId, classroomName: classroom?.name }))
                    }

                    <h1 className="document-boost">{cards[0].title}</h1>

                </div>
                {drawButtons()}
                <p>{cards[0].summary}</p>
                <div className="column-quickcard">
                    {drawCards()}
                    {drawModal()}
                </div>
            </>)
        }
        else {
            return drawLoading();
        }
    }

    const drawLoading = () => {
        return (<h1>{labels.getText()["LOADING"]()}</h1>);
    }


    return (<div className='wrap'>
        <div className="">
            <div className="row ">
                <div className=" wow fadeIn " data-wow-delay="0.1s">
                    {drawMain()}

                </div>
            </div>
        </div>

    </div>
    );

}

export default QuickCard_Screen;
