import '../App.css';
import UserContext from '../components/User_Context';
import { React, useState, useContext, useEffect } from 'react';
import * as labels from "../components/localized/labels";
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../api/apiConnector';

function Blog_Screen() {
    let { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [articles, setArticles] = useState(null);
    const [ids, setIds] = useState(null);
    const [visibleIds, setVisibleIds] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const articlesPerPage = 6;

    const userLanguage = labels.getLanguage();
    user = user ? JSON.parse(user) : null;
    const { language } = useParams();


    const getList = async () => {
        const ids = await api.sendMessage("blog/list", {
            body: {
                user: user,
            }
        });

        if (ids) {
            setIds(ids.ids);
        }
    }

    const getArticles = async () => {
        if (visibleIds) {
            const articles = await api.sendMessage("blog/articles", {
                body: {
                    ids: visibleIds
                }
            });

            if (articles) {
                setArticles(articles.articles);
            }
        }
    }

    const openBlog = (article) => {
        navigate(`/${language}/blog/article/${article}`);
    }

    const _drawSingleArticle = (article) => {
        const _abstract = `${window.$("<div/>").html(article.body).text().substring(0, 200)}...`;
        const _image_url = `url(${api.base_url()}/blog/articles/picture/${article._id})`;
        const _url = `/${language}/blog/article/${article._id}`;

        return (<a href={_url} title={_abstract}><div
        key={`article_${article._id}`}
            className="container my-5 bg-dark rounded shadow-lg img-fluid"
            style={{
                cursor: "pointer",
                backgroundImage: _image_url,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                color: 'white',
                height: "40vh",
                width: "40vh",
                margin: 'auto',
                borderRadius: 8,
                position: 'relative',
                display: 'flex',
                alignItems: 'flex-end',
                textAlign: 'left',
                padding: 10,
                border: 10
            }}
          
            title={_abstract}
        >
            <div style={{
                borderRadius: 12,
                paddingLeft: 10,
                color: 'white',
                textShadow: '2px 2px 2px rgba(1, 1, 1, 1)',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <h3 className="title my-3" style={{ color: 'white', fontWeight: 'bold', fontSize: '1.8rem', textShadow: '1px 1px 2px rgba(1,1,1,1)' }}>
                    {article.title}
                </h3>

                <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                    <i className="fas fa-calendar-alt" style={{ marginRight: '0.5rem' }}></i>
                    <span style={{ marginRight: '1.5rem' }}>
                        <b>{new Date(article.documentCreated).toLocaleDateString()}</b>
                    </span>
                    <i className="fas fa-user" style={{ marginRight: '0.5rem' }}></i>
                    {article.documentLastUpdatedByName ? (
                        <span><b>{article.documentLastUpdatedByName}</b></span>
                    ) : (<span><b>{article.documentCreatedByName}</b></span>)
                    }
                </div>
            </div>
        </div></a>)
    }

    useEffect(() => {
        getList();
    }, []);

    const _getVisibleArticlesList = () => {
        const _startIndex = (pageNumber - 1) * articlesPerPage;
        const _endIndex = (pageNumber) * articlesPerPage;
        const _visibleArticles = ids.slice(_startIndex, _endIndex);
        setVisibleIds(_visibleArticles);
    }

    useEffect(() => {
        if (ids) {
            _getVisibleArticlesList();
        }
    }, [ids]);

    useEffect(() => {
        if (ids) {
            window.$(".wow").css("opacity", "0.5");
            _getVisibleArticlesList();
        }
    }, [pageNumber]);

    useEffect(() => {
        if (articles) {
            window.$(".wow").css("opacity", "1");
        }
    }, [articles]);

    useEffect(() => {
        if (visibleIds) {
            (async () => {
                const articles = await api.sendMessage("blog/articles", {
                    body: {
                        ids: visibleIds,
                    }
                });

                if (articles) {
                    setArticles(articles.articles);
                }
            })();
        }
    }, [visibleIds]);


    const _drawPagination = () => {
        var pagesCount = Math.trunc(ids.length / articlesPerPage) +
            ((ids.length) % articlesPerPage > 0 ? 1 : 0);

        var pages = [];

        for (var i = 1; i <= pagesCount; i++) {
            pages.push({ pageNumber: i, active: (pageNumber == i) });
        }

        const totalPages = pages.length;
        let startPage, endPage;

        if (totalPages <= 5) {
            // less than 5 total pages so show all pages
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 5 total pages so calculate start and end pages
            if (_activePage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (_activePage + 2 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = _activePage - 2;
                endPage = _activePage + 2;
            }
        }

        const _showPrevious = pageNumber > 1;
        const _showNext = pageNumber < endPage;

        const paginationItems = Array.from({ length: (endPage - startPage + 1) }, (_, i) => startPage + i);

        return (
            <div className="row font-monospace pagination-row"
                style={
                    {
                        WebkitUserSelectelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        userSelect: "none"
                    }
                }
            >
                <hr className="hiddenMobile" />

                <div className="pagination">
                    {/* web previous button */}


                    <button className="pagination-button hiddenMobile"
                        onClick={() => {
                            if (pageNumber > 1) {
                                setPageNumber(pageNumber - 1);
                            }
                        }}
                        disabled={_showPrevious ? null : true}

                    >
                        <img src='/huge-icon/arrow/arrow-left.svg '></img>

                        {labels.getText()["PREVIOUS"]()}
                    </button>
                    {/* mobile previous button */}

                    <button className="pagination-button onlyMobile"
                        onClick={() => {
                            if (pageNumber > 1) {
                                setPageNumber(pageNumber - 1);
                            }
                        }}
                        disabled={_showPrevious ? null : true}
                        style={{
                            opacity: _showPrevious ? 1 : 0.5
                        }}
                    >
                        <img src='/huge-icon/arrow/arrow-left.svg '></img>

                    </button>




                    <div className="pagination-numbers">

                        {paginationItems[0] > 1 && (
                            <>
                                <button className="pagination-number-base active"
                                    title={`Go to page 1`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setPageNumber(1)}
                                >1</button>

                            </>
                        )}

                        {paginationItems.map(pn => (
                            <button
                                key={`goToPage_${pn}`}
                                title={`Go to page ${pn}`}
                                className={`col${(pageNumber === pn) ? " pagination-number-base-active" : " pagination-number-base"} `}
                                style={{ cursor: pageNumber !== pn ? "pointer" : "inherit" }}
                                onClick={() => setPageNumber(pn)}
                            >
                                {pn}
                            </button>
                        ))}

                        {paginationItems[paginationItems.length - 1] < pages.length && (
                            <>
                                {paginationItems[paginationItems.length - 1] < pages.length - 1
                                    // && <Pagination.Ellipsis className="col" />
                                }
                                <button
                                    className="col pagination-number-base"
                                    title={`Go to page ${pages.length}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setPageNumber(pages.length)}
                                >
                                    {pages.length}
                                </button>
                            </>
                        )}

                    </div>



                    <button className="pagination-button hiddenMobile"
                        onClick={() => {
                            if (pageNumber < pages.length) {
                                setPageNumber(pageNumber + 1);
                            }

                        }}
                        disabled={_showNext ? null : true}
                    >
                        {labels.getText()["NEXT"]()}
                        <img src='/huge-icon/arrow/arrow-right.svg '></img>

                    </button>

                    {/* mobile next button */}
                    <button className="pagination-button onlyMobile"
                        onClick={() => {
                            if (pageNumber < pages.length) {
                                setPageNumber(pageNumber + 1);
                            }

                        }}
                        disabled={_showNext ? null : true}
                        style={{
                            opacity: _showNext ? 1 : 0.5
                        }}
                    >

                        <img src='/huge-icon/arrow/arrow-right.svg '></img>

                    </button>


                </div>
            </div>
        );

    }

    if (articles) {
        return (<div className='wrap'>
            <div className="container py-5">
                <div>
                    <div className="wow fadeIn " data-wow-delay="0.1s">
                        <div className="stack-title">
                            {getBreadcrumb({ page: "blog" })}
                            {SetMenu({ user: user, section: "blog" })}
                            <h1 className="document-boost">BLOG</h1>
                        </div>
                        <p>{labels.getText()["BLOG_INTRO"]()}</p>
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(40vh, 1fr))" }}>
                            {articles
                                .sort((a, b) => new Date(b.documentCreated) - new Date(a.documentCreated))
                                .map((article, index) => (
                                    _drawSingleArticle(article)
                                ))}
                        </div>
                        {_drawPagination()}
                    </div>
                </div>
            </div>
        </div>);
    }
    else {
        return (
            <div className="wrap">
                {/* {SetMenu({ user: user, professorclassroomId, classroomId })} */}
                <h1>{labels.getText()["WAITING_FOR_DATA"]()}</h1>
            </div>
        );
    }
}

export default Blog_Screen;
