import '../../App.css';
import { React, useState, useEffect, useContext } from 'react';
import * as api from '../../api/apiConnector';
import UserContext from '../../components/User_Context';
import { useNavigate, useParams } from 'react-router-dom';
import * as utils from '../../libs/utils.js'
import * as labels from "../../components/localized/labels";
import { SetMenu } from '../../components/Navigation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
function Blog_Editor_Screen() {

    let { user } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;

    const { id, language } = useParams();
    const [article, setArticle] = useState(null);
    const allowedTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/heif'];

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'align': [] }],
            ['link', 'image', 'video', 'formula'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        'background',
        'script',
        'list',
        'indent',
        'direction',
        'align',
        'link',
        'image',
        'video',
        'formula'
    ];

    const _updateLocally = (article) => {
        setArticle(utils.clone(article));
    }
    //use Effect
    useEffect(() => {
        (async () => {
            getData();
        })();
    }, []);

    useEffect(() => {
        _drawPage();
    }, [article]);





    const getData = async () => {
        let article;
        if (id) {
            let retrieveArticle = (await api.sendMessage("admin/blog/getArticle", {
                body: {
                    id: id,
                },
                user: user,
            }));

            if (retrieveArticle.success) {
                article = retrieveArticle.article
            } else {
                withReactContent(Swal).fire({
                    title: <i>{labels.getText()["ERROR"]()}</i>,
                    icon: "error",
                    text: labels.getText()[`${retrieveArticle.message}`](),
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    showCloseButton: true,
                });
            }



        }
        else {
            article = {
                title: "New Article",
                body: "Insert your content here",
                language,
                isVisible: false,
                image: null
            }
        }


        setArticle(utils.clone(article));

    }

    const _saveArticle = async () => {
        withReactContent(Swal).fire({
            // title: <i>{labels.getText()["ERROR"]()}</i>,
            icon: "info",
            text: labels.getText()["SAVING_IN_PROGRESS"](),
            showConfirmButton: false,
            allowOutsideClick: false,
            showCloseButton: true,
        });

        var result = (await api.sendMessage("admin/blog/saveArticle", {
            body: {
                article: article,
            },
            user: user,
        }));

        if (result.success) {
            withReactContent(Swal).fire({
                // title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "success",
                text: labels.getText()["ARTICLE_SAVED"](),
                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: true,
            });
            if (!id) {
                window.location.href = `/${language}/blogEditor/${result.articleId}`;
            }
        }
        else {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: labels.getText()[`${result.message}`](),
                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: true,
            });
        }
    }
    const handleGenerateImage = async () => {

        withReactContent(Swal).fire({
            title: "Generating Image",
            icon: "info",
            text: "Generating image",
            showConfirmButton: true,
            allowOutsideClick: false,
            showCloseButton: true,

        });

        var titleOfTheArticle = article.title;
        var result = (await api.sendMessage("generateImage", {
            body: {
                imagePrompt: titleOfTheArticle
            },
            user,
        }));


        if (result.success) {
            withReactContent(Swal).fire({
                title: "Image generated",
                icon: "success",
                text: "Image created without problem",
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,

            });
            article.image = `data:image/jpeg;base64,${result.image}`;

            _updateLocally(article);
        }
        else {
            withReactContent(Swal).fire({
                title: "Error",
                icon: "error",
                text: "Could not generate image",
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,

            });
        }
    };

    const handleButtonClick = () => {
        window.$("#file-upload").click();
    };

    // Handle file change event
    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                alert('Please upload a valid image file (JPEG, PNG, HEIC).');
                return;
            }
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result;
                article.image = base64String;
                _updateLocally(article);
            };

            reader.readAsDataURL(file);  // Read file as Data URL
        }
    };



    const _drawImageDiv = () => {
        if (article.image) {


            return _drawImagePreview()
        }

        return _drawImageSelection()




    }

    const _drawImagePreview = () => {

        return (
            <div className='d-flex flex-column align-items-center mt-4'>
                <h3>Thumbnail Preview:</h3>
                <br></br>
                <div style={{ position: 'relative' }}>
                    <img src={article.image} alt="Preview" className="img-thumbnail" />
                    <button
                        className='btn-close'
                        style={{
                            position: 'absolute',
                            background: 'transparent',
                            border: 'none',
                            fontSize: '3rem',
                            top: '-50px',
                            color: 'black',
                            cursor: 'pointer',
                            outline: 'none'
                        }}
                        onClick={(e) => {
                            article.image = null;
                            _updateLocally(article);
                        }}
                        aria-label="Close"
                    >
                        &times;
                    </button>
                </div>
            </div>

        )
    }

    const _drawImageSelection = () => {
        return (<>
            <div className='d-flex flex-column align-items-center'>

                <label className='mb-3'>Upload Thumbnail</label>

                <input
                    id="file-upload"
                    className="btn btn-primary button--lg"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />

                <button type="button" className="btn btn-primary button--lg" onClick={handleButtonClick}>
                    Upload File
                </button>

            </div>
            <div className='d-flex flex-column align-items-center'>
                <label className='mb-3'>Or generate an AI image from the article name</label>

                <button type="button" className="btn btn-primary button--lg" onClick={handleGenerateImage}>
                    Generate Image
                </button>

            </div>
        </>)
    }

    const _drawPage = () => {
        if (article) {
            return (<>
                < div className="stack-title-about">

                    <h1 className="title"> {article.title}</h1>
                </div>

                <div className="row d-flex justify-content-center h-100">
                    <div className="form-outline mb-4 Text700__2">
                        <p className="form-label Text700__2" >
                            {labels.getText()["NAME"]()}
                        </p>
                        <input type="text" id="name" title={labels.getText()["NAME"]()} className="form-control form-control-lg" onChange={(e) => {
                            article.title = e.target.value;
                            _updateLocally(article);
                        }} value={article.title} required />

                        <br></br>
                        <p className="form-label Text700__2" >
                            Body
                        </p>
                        <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={article.body}
                            onChange={
                                (e) => {
                                    article.body = e;
                                    _updateLocally(article);

                                }}></ReactQuill>

                        <br></br>
                        <div className='question-title'>
                            <p className="stretch  Text700__2 ">ABSTRACT</p>
                            <textarea className="form-control inputTextArea Text500__7 neutral500"
                                onChange={(e) => {
                                    article.abstract = e.target.value;
                                   _updateLocally(article)
                                }}
                                value={article.abstract}
                                 style={{ height: 350 }}></textarea>
                        </div>
                        <br></br>


                        {
                            _drawImageDiv()
                        }



                    </div>
                    <div className="d-flex justify-content-around align-items-center mb-4">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="isVisible"
                                onClick={(e) => {
                                    article.isVisible = e.target.checked;
                                    _updateLocally(article);
                                }}
                                onKeyDown={(e) => {

                                }}
                                checked={article.isVisible}
                            />
                            <label className="form-check-label" htmlFor="isVisible">
                                <p className='Text500__6'>{labels.getText()["MAKE_VISIBILE"]()}</p> </label>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center mb-4">
                        <button
                            onClick={(e) => {
                                _saveArticle()
                            }}
                            className="then-big-button animated slideInRight"

                        >{labels.getText()["SAVE"]()}</button>
                    </div>


                </div>
            </>)
        } else {
            return (<>
                < div className="stack-title-about">

                    <h1 className="title"> "{labels.getText()["LOADING"]()}"</h1>
                </div></>)
        }

    }


    return (
        <>


            <div className='wrap'>
                <div className="container py-5">
                    {SetMenu({
                        user: user,
                        section: 'aboutANNA'
                    })}


                    {/* {getBreadcrumb({ page: "classRoomScreen", })} */}
                    <div className="row g-5">
                        <div className="col-lg-12 wow fadeIn " data-wow-delay="0.1s">
                            <div id="registrationBody" className="container py-4 h-100 shadow my-5 bg-white rounded">

                                {_drawPage()}

                            </div>
                        </div >
                    </div >                        </div>

            </div >


        </>
    )



}

export default Blog_Editor_Screen