import '../App.css';
import UserContext from '../components/User_Context';
import { React, useState, useContext, useEffect } from 'react';
import * as labels from "../components/localized/labels";
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../api/apiConnector';
import TagManager from "react-gtm-module";
import { useCookies } from 'react-cookie';

function Single_Blog_Screen() {
    let { user } = useContext(UserContext);
    const { id, title, language } = useParams();
    const [article, setArticle] = useState(null);
    const [cookiesThirdParty, setThirdPartyCookie] = useCookies(localStorage.getItem("thirdPartyCookies"));
    user = user ? JSON.parse(user) : null;
    const navigate = useNavigate();

    const getData = async () => {
        const data = await api.sendMessage("blog/getArticle", {
            body: {
                id: id,
            }
        });
        if (data.article) {
            setArticle(data.article);
        }
        else {
            navigate(`/${language}/blogs`)
        }
    }
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (article) {
            let _div = window.$("#article-body");
            let _images = _div.find("img").toArray();
            for (const e of _images) {
                const _img = window.$(e);
                const _cont = window.$(`<div style="text-align: center"/>`);
                _cont.insertBefore(_img);
                _img.css("text-align", "center");
                _img.css("max-width", "75%");
                _img.css("border-radius", "20px");
                _img.css("box-shadow", "8px 10px 12px rgba(0, 0, 0, 0.6)");
                _cont.append(_img);
            }
        }
    }, [article]);



    if (article) {
        if (process.env.REACT_APP_ENEABLE_TRACKING && cookiesThirdParty?.cookieConsent) {

            try {
                TagManager.dataLayer({
                    dataLayer: {
                        event: "blogOpen",
                        // category: "category",
                        action: "action",
                        // label: "label",
                        value: article.title,
                    },
                });
            } catch (err) {
                console.log(err);
            }
        }



        return (<div className='wrap'>
            <div className="container py-5">
                <div>
                    <div className="wow fadeIn " data-wow-delay="0.1s">
                        <div className="stack-title">
                            {getBreadcrumb({ page: "blog", id: article._id, title: article.title })}
                            {SetMenu({ user: user, section: "blog" })}
                            <h1 className="document-boost">{article.title}</h1>
                        </div>
                        <div
                            className="my-5 bg-dark rounded shadow-lg img-fluid"
                            style={{
                                backgroundImage: `url(${article.image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                color: 'white',
                                height: '40vh',
                                //  maxWidth: '900px',
                                width: '100%',
                                margin: 'auto',
                                borderRadius: '8px',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'flex-end',
                                textAlign: 'left',
                                padding: '10px'
                            }}
                        >
                            <div style={{

                                left: '20px',
                                borderRadius: '12px',
                                paddingLeft: '10px',
                                // backgroundColor: 'rgba(0, 0, 0, 0.2)', // Semi-transparent dark background
                                color: 'white',
                                textShadow: '2px 2px 2px rgba(1, 1, 1, 1)',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <h3 className="title my-3" style={{ color: 'white', fontWeight: 'bold', fontSize: '1.8rem', textShadow: '1px 1px 2px rgba(1,1,1,1)' }}>
                                    {article.title}
                                </h3>

                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                                    <i className="fas fa-calendar-alt" style={{ marginRight: '0.5rem' }}></i>
                                    <span style={{ marginRight: '1.5rem' }}>
                                        <b>{new Date(article.documentCreated).toLocaleDateString("lt-LT", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                        })}</b>
                                    </span>
                                    <i className="fas fa-user" style={{ marginRight: '0.5rem' }}></i>
                                    {article.documentLastUpdatedByName ? (
                                        <span><b>{article.documentLastUpdatedByName}</b></span>
                                    ) : (<span><b>{article.documentCreatedByName}</b></span>)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="container py-4 h-100 shadow my-5 bg-white rounded">
                            <div className="blog-wrapper">
                                <p id="article-body" className="Text500__4 neutral800"  dangerouslySetInnerHTML={{ __html: article.body }}></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>);
    }
    else {

        return (
            <div className="wrap">
                {SetMenu({ user: user })}
                <h1>{labels.getText()["WAITING_FOR_DATA"]()}</h1>
            </div>
        );
    }
}

export default Single_Blog_Screen;
