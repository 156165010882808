import { React, Link } from 'react';
import { urls } from "./labels.js";
import changeLanguage from "../../imgs/aboutAnna/changeLanguage.png"
import folderExample from "../../imgs/aboutAnna/folderExample.png"
import fileUpload from "../../imgs/aboutAnna/fileUpload.gif"
import imgUpload from "../../imgs/aboutAnna/imgUpload.gif"
import audioUpload from "../../imgs/aboutAnna/audioUpload.png"
import mindMap from "../../imgs/aboutAnna/mindmap.png"


const getText = () => {
    return {
        "SECONDS": (params) => {
            const { value = 0 } = params;
            return `${value} sec`;
        },

        "GENERIC_ERROR": (params) => {
            const { error = "Generic error GE_001" } = params;
            return `Nous avons eu un problème lors de l'opération: ${error}`;
        },
        "USER_EXISTS": () => {
            return "Cette adresse e-mail a déjà été utilisée !";
        },
        "MISSING_FIELDS": () => {
            return "Remplissez tous les champs!";
        },
        "HOME_WELCOME_MESSAGE": (params) => {
            return (<span>
                Bienvenue dans l'outil innovant pour vos études
            </span>);
        },
        "EMAIL_RESENT": () => {
            return "Courriel reporté";
        },
        "LIBRARY_WELCOME_MESSAGE": (params) => {
            const { user, docs, folders } = params;
            const number = docs?.length || 0 + folders?.length || 0;

            if (number > 0) {
                if (number > 0) {
                    return (<span>Bonjour {user.name}, il y a {number} documents dans votre bibliothèque</span>);
                }
                else {
                    return (<span>Bonjour {user.name}, vous avez {number} document dans votre bibliothèque</span>);
                }
            }
            else {
                return (<span>Il n'y a pas de documents dans votre bibliothèque</span>);
            }
        },

        "YOU_NEED_TO_LOGIN_FIRST": () => {
            return "Vous devez d'abord vous connecter";
        },
        "ACCEPT": () => {
            return `Accepter`;
        },
        "CANCELLED": () => {
            return "Annulé"
        },
        "DECLINE": () => {
            return `Rejeter`;
        },
        "REMOVE": () => {
            return `Retirer`;
        },
        "CLASSROOM_JOINED": () => {
            return `Vous avez accepté l'invitation correctement`;
        },
        "INVITATION_DECLINED": () => {
            return `Vous avez refusé l'invitation`;
        },

        "LIBRARY_WELCOME_MESSAGE_CLASS": (params) => {
            const { classroom, docs, folders } = params;
            const number = (docs?.length || 0) + (folders?.length || 0);

            if (number > 0) {
                if (number > 1) {
                    return (<span>Dans la classe  {classroom?.name},il y a {number} éléments dans la bibliothèque</span>);
                }
                else {
                    return (<span>Dans la classe {classroom?.name}, vous avez {number} élément dans la bibliothèque</span>);
                }
            }
            else {
                return (<span>Dans la classe  {classroom?.name},la bibliothèque est vide</span>);
            }

        },
        "LIBRARY_WELCOME_MESSAGE_2": (params) => {
            return (<span>
                Vos documents ? Je les traite, <strong>ANNA</strong> ! Je vous prépare un résumé, je réponds à toutes vos questions et, si vous le souhaitez, je peux aussi créer des tests pour vérifier vos connaissances.
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE_3": (params) => {
            return (<span>
                Vous téléchargez des images de votre galerie et je les convertis en un document PDF.Il sera lu par mon moteur <strong>AI</strong> qui produira pour vous de courts résumés
                pour vous de courts résumés, les réponses à toutes les questions et, si vous le souhaitez, je peux préparer un test pour vérifier l'état de vos connaissances.
                préparer un test pour vérifier l'état de vos connaissances.
            </span>);
        },
        "LIBRARY_WELCOME_MESSAGE_4": (params) => {
            return (<span>
                Sur la base de vos données audio, je vais créer un nouveau livre pour la bibliothèque. Il sera lu par mon moteur <strong>AI</strong> qui produira pour vous de courts résumés, des réponses à vos questions. des résumés succincts, des réponses aux questions et, si vous le souhaitez, je pourrais préparer un test pour vérifier l'état de vos connaissances.            </span>);
        },

        "DOCUMENT_WELCOME_MESSAGE": (params) => {
            const { linkTo } = params;
            return (<span>
                Vous pouvez ici choisir le type de session AI pour le document sélectionné ou vous pouvez <Link to={linkTo}> revenir à la liste</Link>.            </span>);
        },
        "DOCUMENT_QA_SESSION_TITLE": (params) => {

            return "Q&A";
        },
        "DOCUMENT_QA_SESSION": (params) => {

            return (<span>
                Nous pouvons parler de tout ce qui figure dans le document. Vous pouvez me poser des questions et je vous fournirai des réponses            </span>);
        },
        "DOCUMENT_QA_SESSION_FOLDER": (params) => {

            return (<span>
                Contactez-moi pour toute question concernant les documents de ce dossier. Je vous donnerai toutes les réponses nécessaires.            </span>);
        },
        "TRAINING_SESSION_TITLE": (params) => {

            return (<span>
                Test
            </span>);
        },
        "TRAINING_SESSION": (params) => {

            return (<span>
                Testez vos connaissances. Je peux préparer un test pour vous et vous corriger si vos réponses sont fausses.            </span>);
        },
        "TRAINING_SESSION_FOLDER": (params) => {

            return (<span>
                Testez vos connaissances. Je vous propose un examen blanc de tous les documents de ce dossier et vous indique où trouver les bonnes solutions en cas d'erreur.            </span>);
        },
        "MIND_MAP_SESSION_TITLE": (params) => {

            return "Mindmap";
        },
        "MIND_MAP_SESSION": (params) => {

            return (<span>
                Je peux créer une carte mentale de votre document pour vous aider à réfléchir, à assimiler le contenu et à mieux le mémoriser.            </span>);
        },
        "MIND_MAP_SESSION_FOLDER": (params) => {

            return (<span>
                Je peux vous préparer une carte mentale des documents pour vous aider à réfléchir, à assimiler le contenu et à mieux le mémoriser.            </span>);
        },
        "SUMMARY_SESSION_TITLE": (params) => {

            return (<span>
                Résumé  &amp; traduction
            </span>);
        },
        "SUMMARY_SESSION": (params) => {

            return (<span>
                Je peux résumer et traduire le document pour vous
            </span>);
        },
        "GO": (params) => {

            return (<span>
                Go
            </span>);
        },
        "TRY_IT": (params) => {

            return (<span>
                Essayez-moi
            </span>);
        },
        "CREATE_FOLDER": () => {
            return "Créer un dossier";
        },
        "FOLDER_NAME": () => {
            return "Nom du dossier";
        },
        "MUST_INSERT_CLASSROOM_NAME": () => {
            return "Le nom de la classe doit être saisi";
        },
        "MANAGE_YOUR_CLASS": () => {
            return "Organisez vos cours";
        },
        "INVITE_PROFESSORS": () => {
            return "Inviter un professeur";
        },
        "TELL_ME_MORE": (params) => {

            return (<span>
                En savoir plus
            </span>);
        },
        "SEARCH_MEMBERS": () => {
            return `Recherche d'utilisateurs`;
        },
        "SEARCH_DOCUMENTS": () => {
            return `Recherche de documents`;
        },
        "ASK_ME": (params) => {
            return (<span>
                Demandez-moi quelque chose
            </span>);
        },
        "MEMBERS": () => {
            return `Membres`;
        },
        "MEMBER": () => {
            return `Membre`;
        },
        "GO_TO_PAGE": () => {
            return "Aller à la page ";
        },
        "PREVIOUS": () => {
            return "Précédent";
        },
        "PREVIOUS_PAGES": () => {
            return "Pages précédentes";
        },
        "NEXT_PAGES": () => {
            return "Pages suivantes";
        },
        "NEXT": () => {
            return "Avancer";
        },
        "DELETE": () => {
            return "Eliminer";
        },
        "INVITE_PROFESSOR": () => {
            return `Inviter un professeur`;
        },
        "INSERT_EMAIL": () => {
            return `Saisir l'e-mail`;
        },
        "INVITATION": () => {
            return `Invitation`;
        },
        "JOIN_NEW_CLASSROOM": () => {
            return "Demander l'accès à une nouvelle classe";
        },
        "YOU_CANNOT_ENTER_THIS_CLASS": () => {
            return "Vous devez attendre que le professeur accepte votre invitation pour entrer dans la classe !";
        },
        "YOU_ALREADY_ADDED_THIS_CLASS": () => {
            return "L'utilisateur existe déjà dans cette classe !";
        },
        "WAITING_FOR_APPROVAL": () => {
            return "En attente d'approbation...";
        },
        "MY_CLASSESS": () => {
            return "Mes cours";
        },
        "ADD_CLASSROOM": () => {
            return "Ajouter une classe";
        },
        "ENTER_CODE": () => {
            return "Saisissez le code de la classe à laquelle vous voulez accéder";
        },
        "ENTER_CLASS": () => {
            return "Demande d'accès";
        },
        "EDIT": () => {
            return "Editer";
        },
        "DOWNLOAD_PDF": (params) => {
            return (<span>
                Télécharger le Pdf
            </span>);
        },
        "CLASS_NAME": (params) => {
            const { name } = params;

            return (<span>
                Editer {name}
            </span>);
        },
        "RETURN_TO_DOC": () => {

            return "Retour";
        },
        "DOCUMENT_QA_SESSION_INTRO": (params) => {

            return (<span>
                J'ai lu le document et je peux maintenant répondre à vos questions, alors qu'attendez-vous pour me les poser ?
            </span>);
        },
        "DOCUMENT_UPLOADED": (params) => {
            const { date } = params;

            return (<span>
                Téléchargé le: {date.toLocaleDateString()}.
            </span>);
        },
        "FOLDER_CREATED": (params) => {
            const { date } = params;

            return (<span>
                Créé le: {date.toLocaleDateString()}.
            </span>);
        },
        "PAGES_LIST": (params) => {
            const { pages = [] } = params;

            return pages.length > 1 ? "Pages" : "Page"
        },
        "PAGES_LIST_WITHPAGE": (params) => {
            const { pages = [] } = params;

            var pageNumbers = pages ? pages.join(",") : "-"

            return (<span>{pages?.length > 1 ? "Pages" : "Page"}: {pageNumbers}</span>)

                ;
        },
        "SHARE_RESULT": () => {
            return "Partager les résultats";
        },
        "CONTEXT_HELP": () => {
            return "Conseil: ";
        },
        "ANSWER": () => {
            return "Réponse: ";
        },
        "ANSWERES_CHECKED": () => {
            return `Nous avons vérifié vos réponses !`;
        },
        "CHECKING_ANSWERS": () => {
            return "Je vérifie les réponses";
        },
        "DOWNLOAD": () => {
            return "Télécharger la carte heuristique";
        },
        "COPY_TO_CLIPBOARD": () => {
            return "Copier dans le presse-papiers";
        },
        "COPY_SUMMARY": () => {
            return "Copier";
        },
        "DELETE_QUESTION": () => {
            return "Supprimer le document";
        },
        "PREPARING_PACKAGE": () => {
            return "Préparation aux tests et questions....";
        },
        "PACKAGE_NUMBER": (params) => {
            const { number } = params;
            return `Test #${number}`;
        },
        "PACKAGE": () => {
            return `Test `;
        },
        "PACKAGE_PROCCESS_FINISH": () => {
            return "Votre test est prêt!";
        },
        "PACKAGE_PROCCESS_FINISH_LESS_QUESTIONS": () => {
            return "Impossible de générer le nombre de questions requis. Le document est trop court.";
        },
        "PREPARING_DOCUMENT": () => {
            return "Je suis en train de traiter votre document";
        },
        "DOCUMENT_NUMBER": (params) => {
            const { number } = params;
            return `Document #${number}`;
        },
        "DOCUMENT_PROCCESS_FINISH": () => {
            return "J'ai traité toutes les informations contenues dans le document !";
        },
        "DOCUMENT_NAME_MINDMAP": (params) => {
            const { title } = params;
            return `${title}`;
        },
        "PREPARING_MINDMAP": () => {
            return "Je prépare la mindmap...";
        },

        //#region breadcrumb
        "BREADCRUMB_HOME": () => {
            return "Home";
        },
        "BREADCRUMB_DOCUMENT": (params) => {
            const { name } = params;
            return `${name}`;
        },
        "BREADCRUMB_QA": () => {
            return "Q & A";
        },
        "BREADCRUMB_TRAINING": () => {
            return "Test";
        },
        "BREADCRUMB_SURVEY": () => {
            return "Questionnaire";
        },
        "BREADCRUMB_SURVEY_ID": (params) => {
            const { id } = params;
            return `${id}`;
        },
        "BREADCRUMB_MINDMAP": () => {
            return "Mindmap";
        },
        "BREADCRUMB_SUBMINDMAP": () => {
            return "Mindmap secondaire";
        },

        //#endregion

        "ADD": () => {
            return "Ajouter ";
        },
        "DOCUMENT_FOLDERS": () => {
            return "Documents et dossiers dans la salle de classe";
        },
        "ABOUT_CLASS": () => {
            return "Informations sur la classe";
        },
        "ADD_DOCUMENT": () => {
            return "Ajouter un nouveau document";
        },
        "ADD_FOLDER": () => {
            return "Ajouter un nouveau dossier";
        },
        "CLASSROOM_PROFESSORS": () => {
            return "Enseignants présents dans la classe";
        },
        "CLASSROOM_STUDENTS": () => {
            return "Les étudiants qui sont dans la classe ou qui ont demandé l'accès";
        },
        "CREATED_BY": () => {
            return "créé par:";
        },
        "ADD_TO_FOLDER": () => {
            return "Ajouter au dossier";
        },
        "DELETE_FOLDER": () => {
            return "Supprimer le dossier";
        },
        "UPLOAD_NEW_FILE": () => {
            return "Ajouter un nouveau document";
        },

        "UPLOAD_NEW_FILE_SMALL": () => {
            return "Si vous le souhaitez, vous pouvez également télécharger un nouveau document ici";
        },

        "BOOKLIST_SMALL_MESSAGE": (params) => {
            const { uploadDate, uploadTime } = params;

            return `Téléchargé le ${uploadDate} à ${uploadTime}`;
        },

        "YOUR_LIBRARY": () => {
            return "VOTRE BIBLIOTHÈQUE";
        },
        "CAMERA_UPLOAD": () => {
            return "Chargement à partir de l'appareil photo";
        },
        "VOICE_UPLOAD": () => {
            return "Chargement de la parole vers le texte";
        },
        "LOGIN_CREDENTIAL": () => {
            return "Entrez vos informations d'identification";
        },
        "EMAIL_ADDRESS": () => {
            return "Courrier électronique";
        },
        "PASSWORD": () => {
            return "Mot de passe";
        },
        "CONFIRM_PASSWORD": () => {
            return "Confirmer le mot de passe";
        },
        "REMEMBER_ME": () => {
            return "Rappelez-moi";
        },
        "CHOOSE": () => {
            return `Choisissez votre pays`;
        },
        "NO_OPTIONS": () => {
            return `Pas de résultats`;
        },
        "ACCEPT_T&C": () => {
            return `Acceptez les termes et conditions!`;
        },
        "PASSWORDS_NO_MATCH": () => {
            return `Les mots de passe ne correspondent pas!`;
        },
        "PLEASE_FILL_IN_THE_FIELDS": () => {
            return `Veuillez remplir tous les champs!`;
        },
        "NOT_GOOD_EMAIL": () => {
            return `Veuillez saisir un courriel valide!`;
        },
        "FORGOT_PASSWORD": () => {
            return "Vous avez oublié votre mot de passe ?";
        },
        "WHO_ARE_YOU": () => {
            return "Qui êtes-vous ?";
        },
        "OPEN": () => {
            return "Ouvrir";
        },

        "GENERAL_INFO": () => {
            return "Informations générales";
        },
        "BREADCRUMB_CLASSROOMS": () => {
            return "Classes";
        },
        "CLASSROOM_DELETE_ERROR": () => {
            return `Des problèmes sont survenus lors de la suppression de la classe`;
        },
        "PAGES": (params) => {
            const { pages } = params;
            if (pages === 0) {
                return `Il n'y a pas de pages dans le document`

            }
            else if (pages === 1) {
                return `${pages} page`
            }
            else {
                return `${pages} pages`
            }
        },

        "CREATE_TEST": () => {
            return "Créer un nouveau test";
        },
        "NO_TEST_TO_SHOW": () => {
            return "Il n'y a pas de tests à afficher";
        },
        "CHECK_TERMS_CONDITIONS_SOCIAL": () => {
            return "Si vous vous inscrivez à Social, vous acceptez  ";
        },
        "CHECK_TERMS_CONDITIONS1": () => {
            return "Je suis d'accord avec ";
        },
        "CHECK_TERMS_CONDITIONS2": () => {
            return "Conditions générales d'utilisation";
        },
        "OLD_PASSWORD": () => {
            return "Ancien mot de passe";
        },
        "NEW_PASSWORD": () => {
            return "Nouveau mot de passe";
        },
        "ENTER_OLD_PASSWORD": () => {
            return "Entrez votre ancien mot de passe";
        },
        "ENTER_NEW_PASSWORD": () => {
            return "Entrez votre nouveau mot de passe";
        },
        "CHANGE_PASSWORD": () => {
            return "Entrez votre nouveau mot de passe";
        },
        "TEST_PAGE_LABELS": (params) => {
            const { user, questionsPackages } = params;
            if (questionsPackages > 0) {
                if (questionsPackages === 1) {
                    return `${user}, nous avons un questionnaire pour ce document`
                }
                else {
                    return `${user}, nous avons ${questionsPackages}  questionnaires pour ce document`
                }
            }
            else {
                return `${user}, nous n'avons pas encore de questionnaire pour ce document`

            }
        },

        "QUESTIONS_COUNT": () => {
            return `Nombre de demandes`;
        },
        "WAIT": () => {
            return "euillez patienter";
        },
        "EXPORT_DOCX": () => {
            return "Exporter vers docx";
        },
        "EXPORT_PPTX": () => {
            return "Exporter vers pptx";
        },
        "ERROR": () => {
            return "Erreur";
        },
        "ERROR_SENDING_MAIL": () => {
            return `Nous avons rencontré un problème lors de l'envoi du courrier. Veuillez réessayer plus tard.`;
        },
        "ERROR_INSERT_VALID_QUESTION": () => {
            return "Saisir une question valide";
        },
        "ERROR_WHILE_CONNECTING_TO_SERVER": () => {
            return "Une erreur s'est produite lors de la connexion au serveur. Veuillez réessayer plus tard..";
        },
        "ERROR_WHILE_CREATING_MINDMAP": () => {
            return "Erreur lors de la création de la mindmap";
        },
        "FOLDER_EXPLANATION": (params) => {
            return (<span>
                Vous pouvez voir ici tous les documents disponibles. Les documents portant le drapeau actif se trouvent déjà dans le dossier.   N'oubliez pas que vous pouvez également ajouter d'autres documents
            </span>);
        },
        "WRONG_CREDENTIALS": () => {
            return "Mauvaises références";
        },
        "I_DONT_KNOW": () => {
            return "Je ne sais pas";
        },
        "TEXT_COPIED_TO_CLIPBOARD": () => {
            return "Texte copié dans le presse-papiers !";
        },
        "LOADING": () => {
            return "Charg...";
        },
        "LEAVE_MESSAGE_HERE": () => {
            return "Demandez-moi tout ce que vous voulez";
        },
        "GROUP_NAME": () => {
            return `Nom du groupe`;
        },
        "DELETING_DOCUMENT": () => {
            return "Suppression de documents";
        },
        "SHOW_MORE": () => {
            return "Afficher plus";
        },
        "SHOW_LESS": () => {
            return "Montrer moins";
        },
        "NO_ELEMENTS_TO_SHOW": () => {
            return "Pas d'articles à montrer";
        },
        "DO_YOU_WANT_TO_DELETE": () => {
            return "Voulez-vous vraiment éliminer";
        },
        "CREATE_GROUP": () => {
            return `Création d'un groupe`;
        },
        "OTP_MODAL_BODY": () => {
            return "Vérifiez votre boîte de réception, où nous avons envoyé le code de confirmation.";
        },
        "OTP_MODAL_TITLE": () => {
            return "Conferma l'e-mail";
        },
        "OTP_MODAL_FIRST_PART_RESEND": () => {
            return "Non hai ricevuto il codice? Clicca ";
        },
        "OTP_MODAL_SECOND_PART_RESEND": () => {
            return "qui ";
        },
        "OTP_MODAL_THIRD_PART_RESEND": () => {
            return "per inviare nuovamente l'e-mail. ";
        },
        "DONE": () => {
            return "Fait";
        },
        "COLOR": () => {
            return `Choisissez une couleur`;
        },
        "CREATE_CLASSROOM": () => {
            return `Créer une nouvelle classe`;
        },
        "ADD_NEW_CLASSROOM": () => {
            return `Ajouter une classe`;
        },
        "ADD_NEW_GROUP": () => {
            return `Ajouter un nouveau groupe`;
        },
        "CLASSROOM_NAME": () => {
            return `Nom de la classe`;
        },
        "TEST_DIFFICULTY": () => {
            return `Difficulté du test`;
        },
        "ANSWERS_TYPE": () => {
            return `Type de questions`;
        },
        "CLOSE_ANSWERS": () => {
            return `Questions fermées`;
        },
        "OPEN_ANSWERS": () => {
            return `Questions ouvertes`;
        },
        "EASY_QUESTIONS": () => {
            return `Facile`;
        },
        "MEDIUM_QUESTIONS": () => {
            return `Médias`;
        },
        "HARD_QUESTIONS": () => {
            return `Difficile`;
        },
        "EXTREME_QUESTIONS": () => {
            return `Extrême`;
        },
        "ANSWERS_QUANTITY": () => {
            return `Type de réponse`;
        },

        "PASSWORD_REQ": () => {
            return "Votre mot de passe ne répond pas aux exigences";
        },
        "CLOSE": () => {
            return `Fermer`;
        },
        "TEST_SMALL_MESSAGE": (params) => {
            const { generatedDate, generatedTime } = params;

            return `Généré le   ${generatedDate} à ${generatedTime}`;
        },
        "SINGLE_CHOICES": () => {
            return "Choix unique";
        },
        "MULTIPLE_CHOICES": () => {
            return "Choix multiple";
        },
        "NQUESTIONS": (params) => {
            const { numbers } = params;

            return `${numbers} questions`;
        },
        "PROGRESS": () => {
            return `Avancement`;
        },
        "INDEXING_PROGRESS": () => {
            return "Statut de l'indexation:";
        },
        "WAITING_FOR_DATA": () => {
            return "En attente de données...";
        },
        "SUBMIT_BUTTON": () => {
            return `Envoyer`;
        },
        "CREATE_PDF": () => {
            return `Créer un PDF`;
        },
        "FACEBOOK_LOGIN": () => {
            return " Se connecter avecFacebook";
        },
        "GOOGLE_LOGIN": () => {
            return " Se connecter avecGoogle";
        },
        "LOGIN": () => {
            return "Login";
        },
        "ARTIFICIAL_INTELLIGENCE_FOR_STUDIES": () => {
            return "L'intelligence artificielle au service de vos études";
        },
        "START_NOW": () => {
            return "Commencer maintenant";
        },
        "REGISTER": () => {
            return "Registre";
        },

        "REGISTER_CREDENTIAL": () => {
            return "Entrez vos données d'identification";
        },
        "ERROR_DURING_CREATION": () => {
            return "Erreur lors de la création du compte!";
        },
        "WRONG_CODE": () => {
            return "Code de confirmation erroné.";
        },
        "USER_CREATED": () => {
            return "Votre compte a été créé et activé avec succès!";
        },
        "NAME": () => {
            return "Nom";
        },
        "SCHOOL_NAME": () => {
            return "Nom de l'école";
        },
        "LAST_NAME": () => {
            return "Nom de famille";
        },
        "USERNAME": () => {
            return "Nom de l'utilisateur";
        },
        "DATE_OF_BIRTH": () => {
            return "Date de naissance";
        },
        "SELECT_COUNTRY": () => {
            return "Sélectionner un pays";
        },
        "CHECK_TERMS_CONDITIONS": () => {
            return "Vérifiez si vous acceptez les termes et conditions";
        },
        "PROBLEM": () => {
            return "Nous avons quelques problèmes";
        },
        "FACEBOOK_PROBLEM": () => {
            return "Nous avons des problèmes avec le login Facebook";
        },
        "GOOGLE_PROBLEM": () => {
            return "Nous avons des problèmes avec le login google....Quelque chose ne fonctionne pas";
        },
        "SELECTED_COLOR": () => {
            return "Sélectionnez une couleur :";
        },
        "CLASSROOM_ADDED_ERROR": () => {
            return `Problème lors de la création de la classe `;
        },
        "CLASSROOM_ADDED": () => {
            return `Classe correctement créée `;
        },
        "DRAG_DROP_FILES": () => {
            return "Glisser-déposer des documents ";
        },
        "NO_FILES_SELECTED": () => {
            return "Aucun document n'est actuellement sélectionné pour le téléchargement ";
        },
        "LANGUAGE_MODAL_LABEL": () => {
            return "Sélectionner la langue ";
        },
        "CLASSROOM_DELETE": () => {
            return `Classe supprimée correctement`;
        },
        "MENU_USER_LOGGED": (params) => {
            const { upperCaseLanguage, user, section } = params;
            let classRoomLink = null;

            if (user) {
                classRoomLink = user.isConfirmedProfessor ? `/fr/${"professor"}/classrooms` : "/fr/classrooms";
            }

            window.$("#navbarCollapse").html(`
                <div class="navbar-nav ms-auto Text500__5">
                <a href="/fr" class="nav-item nav-link ${(section === "library" ? "active" : "")}">Documents </a>
                <a href="/fr/public/quickcards/list" class="nav-item nav-link ${(section === "cards" ? "active" : "")}">Quick cards</a>
                <a href="/fr/aboutANNA" class="nav-item nav-link" ${(section === "classrooms" ? "active" : "")}>À propos d'ANNA</a>
                <a href="/fr${urls.account}/" class="nav-item nav-link ${(section === "settings" ? "active" : "")}">Compte</span>
                <strong><a href="/fr${urls.logout}" class="nav-item nav-link">Déconnexion</a></strong>
                ${urls.language_selector.replace("##LANGUAGE##", upperCaseLanguage)}
              
              </div>`);

        },
        "MENU_USER_NOT_LOGGED": (params) => {
            const { upperCaseLanguage } = params;
            window.$("#navbarCollapse").html(`
                <div class="navbar-nav ms-auto Text500__5">
               <a href="/fr/public/quickcards/list" class="nav-item nav-link active">Quick cards</a>
                <a href="/fr/aboutANNA" class="nav-item nav-link">À propos d'ANNA </a>
                <a href="/fr/contactus" class="nav-item nav-link">Contact</a>
                <strong><a href="/fr/login" class="nav-item nav-link" id="LoginButtonNav">Connecter</a></strong>
                 ${urls.language_selector.replace("##LANGUAGE##", upperCaseLanguage)}
                 <a href="/fr/register" id="try-button-menu" class="then-small-button ml-5">Essai GRATUIT</a>

            </div>`);

        },

        "FOOTER_BOTTOM_MENU_LOGGED": () => {
            window.$("#rightsReserved").html(`
             
                &copy; <a href='https://www.labh9.eu' target='_blank' rel="noopener noreferrer">ANNA</a>, Tous droits réservés.          
            `);
            window.$("#secondFooterColumn").html(`
                                <div class="linkRowFooter">
                                    <a href="/fr" class="linkFooter">Ma bibliothèque</a>
                                    <a href="https://www.labh9.eu" class="linkFooter" target='_blank' rel="noopener noreferrer">A propos de nous</a>
                                    <a href="/fr/contactus" class="linkFooter">Contact</a>
                                    <a href="/fr/terms_and_conditions" class="linkFooter">Conditions générales d'utilisation</a>
                                    <a href="/fr/privacy_policy" class="linkFooter">Privacy</a>
                                    <a href='https://www.labh9.eu'  class="linkFooter" target='_blank' rel="noopener noreferrer">Powered by<img class="footerPoweredLogo"  src="/Labh9logo.png"></img></a>
                                </div>  
            `);
        },
        "FOOTER_BOTTOM_MENU_NOT_LOGGED": () => {
            window.$("#rightsReserved").html(`
                                &copy; <a href='https://www.labh9.eu' target='_blank' rel="noopener noreferrer">ANNA</a>,Tous droits réservés. 
                            `);
            window.$("#secondFooterColumn").html(`
                 <div class="linkRowFooter">
                                    <a href="/fr/aboutANNA" class="linkFooter" target='_blank' rel="noopener noreferrer">A propos de nous/a>
                                    <a href="/fr/contactus" class="linkFooter">Contact</a>
                                    <a href="/fr/terms_and_conditions" class="linkFooter">Conditions générales d'utilisation</a>
                                    <a href="/fr/privacy_policy" class="linkFooter">Privacy</a>
                                    <a href='https://www.labh9.eu'  class="linkFooter" target='_blank' rel="noopener noreferrer">Powered by<img class="footerPoweredLogo"  src="/Labh9logo.png"></img></a>
                                </div>  
            
            `);
        },
        "ADD_TO_CLASSROOM": () => {
            return `Partager avec une classe`;
        },
        "CREATE_CARD": () => {
            return `Créer une nouvelle classe`;
        },
        "FOLDER_CREATION_DATE": (params) => {
            const { uploadDate, uploadTime } = params;

            return `Créé en  ${uploadDate} à ${uploadTime}`;
        },
        "CREATE_YOUR_IDENTITY": () => {
            return "Créez votre compte gratuit";
        },
        "PASSWORD_REQUIREMENTS": () => {
            window.$("#password-Req").html(`
              <div className="password-requirements" id="password-Req">
                                        <p>Le mot de passe doit répondre aux exigences suivantes:</p>
                                        <ul>
                                            <li>Au moins 8 caractères</li>
                                            <li>Contient au moins une lettre majuscule</li>
                                            <li>Contient au moins une lettre minuscule</li>
                                            <li>Contient au moins un chiffre</li>
                                            <li>Contient au moins un caractère spécial</li>
                                        </ul>
                                    </div>
            
            `);
        },
        "ENTER_NAME": () => {
            return "Entrez votre nom";
        },
        "ENTER_EMAIL": () => {
            return "Saisissez votre adresse électronique";
        },
        "ENTER_LASTNAME": () => {
            return "Saisissez votre nom de famille";
        },
        "ENTER_PASSWORD": () => {
            return "Entrez votre mot de passe";
        },
        "ENTER_BIRTHDATE": () => {
            return "Saisissez votre date de naissance";
        },
        "ENTER_COUNTRY": () => {
            return "Entrez votre pays";
        },
        "ENTER_CONFIRM_PASSWORD": () => {
            return "Confirmer le mot de passe";
        },
        "ENTER_SCHOOL": () => {
            return "Entrez votre école";
        },
        "PASS_REQS": () => {
            return "Le mot de passe doit remplir les conditions suivantes:";
        },
        "PASS_REQS1": () => {
            return "Au moins 8 caractères";
        },
        "PASS_REQS2": () => {
            return "Contient au moins une lettre majuscule";
        },
        "PASS_REQS3": () => {
            return "Contient au moins une lettre minuscule";
        },
        "PASS_REQS4": () => {
            return "Contient au moins un chiffre";
        },
        "PASS_REQS5": () => {
            return "Contient au moins un caractère spécial";
        },
        "WHOAREYOU_MODAL": () => {
            return "Comment voulez-vous vous inscrire?"
        },
        "NORMAL_USER": () => {
            return "Utilisateur"
        },
        "USER_PROFILE": () => {
            return "Votre profil"
        },
        "PROFESSOR_USER": () => {
            return "Professeur"
        },
        "ADD_MODAL_ELEMENT": () => {
            return "Que voulez-vous ajouter ?"
        },
        "UPLOAD": () => {
            return "Télécharger "
        },
        "CLEAR": () => {
            return "Supprimer "
        },
        "PRESS_MIC_TO_UPLOAD": () => {
            return "Appuyez sur le microphone pour charger"
        },
        "UPLOAD_TRANSCRIBE": () => {
            return "Télécharger et transcrire "
        },
        "OR": () => {
            return "ou"
        },
        "CLASSROOM": () => {
            return "Classe";
        },
        "YOU_HAVE_BEEN_INVITED_IN_CLASS": (params) => {
            const { classroom } = params;

            return `Vous avez reçu une invitation par classe ${classroom}`;
        },
        "REMOVE_STUDENT_HEADER": () => {
            return `Retirer l'élève`;
        },
        "REMOVE_STUDENT_BODY": () => {
            return `Vous êtes sur le point de retirer l'élève de la classe, êtes-vous sûr de vouloir continuer ?`;
        },
        "AUDIO_EXPLANATION": () => {
            return "Appuyez sur la touche pour commencer l'enregistrement et relâchez-la lorsque vous souhaitez procéder à l'envoi."
        },
        "AUDIO_FILE_PROGRESS": () => {
            return "Je suis en train d'analyser l'audio..."
        },
        "REMOVE_STUDENT_BUTTON": () => {
            return "Retirer l'élève";
        },
        "STUDENT_DELETE_DONE": () => {
            return "Élève retiré";
        },
        "STUDENT_DELETE_SUCCESSFULLY": () => {
            return "L'élève est renvoyé de la classe avec succès";
        },
        "STUDENT_DELETE_PROBLEM": () => {
            return "Il y a eu des problèmes lors de la suppression, l'utilisateur n'a pas été supprimé.";
        },
        "NO_CLASS_FOR_CODE": () => {
            return "Nous n'avons trouvé aucune classe avec ce code";
        },
        "USER_JUST_IN_CLASSROOM": () => {
            return "Êtes-vous déjà dans la classe ou attendez-vous que l'enseignant accepte votre demande ?";
        },
        "MISSING_CODE": () => {
            return "Vous devez saisir le code pour envoyer la demande";
        },
        "DOCUMENT_NAME": () => {
            return "Nom du document";
        },
        "UPLOADED_IN": () => {
            return "Date de téléchargement";
        },
        "ELEMENT_TYPE": () => {
            return "Type";
        },
        "PROFESSOR_NAME": () => {
            return "Professeur";
        },
        "EMAIL_PROFESSOR": () => {
            return "Courrier électronique";
        },
        "MOBILE_PHONE": () => {
            return "Téléphone";
        },
        "PROFESSOR_ACTION": () => {
            return "Supprimer";
        },
        "REMOVE_PROFESSOR_HEADER": () => {
            return `Supprimer le professeur`;
        },
        "REMOVE_PROFESSOR_BODY": () => {
            return `Vous êtes sur le point de retirer l'enseignant de la classe. Êtes-vous sûr de vouloir continuer ?`;
        },
        "REMOVE_PROFESSOR_BUTTON": () => {
            return `Supprimer`;
        },
        "PROFESSOR_DELETE_DONE": () => {
            return "Suppression du professeur";
        },
        "PROFESSOR_DELETE_SUCCESSFULLY": () => {
            return "Un enseignant renvoyé de sa classe avec succès";
        },
        "PROFESSOR_DELETE_PROBLEM": () => {
            return "Il y a eu des problèmes lors de la suppression, l'utilisateur n'a pas été supprimé.";
        },
        "GO_TO_LIBRARY": () => {
            return "Documents";
        },
        "CLASSROOM_CODE_LABEL_PROFESSOR": (params) => {
            const { code } = params;
            return (<span>
                Code d'identification de la classe : <strong>{code ?? ""}</strong>
            </span>);
        },
        "NO_DOCUMENTS_GRID": () => {
            return ("Pas de document dans la classe");
        },
        "NO_PROFESSOR_GRID": () => {
            return ("Pas d'enseignant dans la classe");
        },
        "NO_STUDENT_GRID": () => {
            return ("Pas d'élèves dans la classe");
        },
        "BOOKS_INSIDE": (params) => {
            const { number } = params;
            if (number > 0) {
                if (number > 1) {
                    return (

                        <small>{`${number} documents`}</small>)
                }
                else {
                    return (

                        <small>{`${number} document`}</small>)
                }
            } else {
                return (
                    <small>{`Pas de document`}</small>)
            }
        },
        "CLASSROOM_LIBRARY": () => {
            return "Documents de classe";
        },
        "OTP_MODAL_DELETE_TITLE": () => {
            return "Êtes-vous sûr de vouloir supprimer l'utilisateur ?";
        },
        "EDIT_CARD": () => {
            return `Sauvegarder`;
        },
        "WELL_DONE": () => {
            return "Bien joué!";
        },

        "ALL_CORRECT": (params) => {
            const { maxScore } = params;

            return `Félicitations, vous avez répondu correctement à toutes les${maxScore} questions!`;
        },
        "TOPIC_EXPERT": () => {
            return "Vous êtes désormais un expert en la matière";
        },
        "GOOD_JOB": () => {
            return "Bon travail !";
        },
        "SOMEONE_CORRECT": (params) => {
            const { actualScore, maxScore } = params;
            return `Félicitations, vous avez répondu correctement à ${actualScore} quesitions sur ${maxScore}!`;
        },
        "SOMEONE_CORRECT_SAD": (params) => {
            const { actualScore, maxScore } = params;
            return `Dommage, vous avez répondu correctement à  ${actualScore} quesitions sur ${maxScore} !`;
        },
        "TEST_TRY_AGAIN": () => {
            return "Vérifier les mauvaises réponses et réessayer!";
        },
        "STUDY_MORE": () => {
            return "Vous devez étudier davantage!";
        },
        "ANSWER_NOT_SELECTED": () => {
            return "Vous n'avez sélectionné aucune réponse!";
        },
        "YOU_ANSWERED": () => {
            return "Votre réponse";
        },
        "BACK_TO_QUESTIONS": () => {
            return "Retour aux questions";
        },

        "LEAVE_CLASSROOM": () => {
            return "Retour aux questions?";
        },
        "LEAVE_CLASSROOM_MESSAGE": () => {
            return "Êtes-vous sûr de vouloir quitter la classe ?";
        },
        "LEAVE_CLASSROOM_BUTTON": () => {
            return "Quitter la classe";
        },
        "DELETE_ELEMENT_MODAL": () => {
            return "Vous êtes sur le point de supprimer un élément";
        },
        "REMOVE_ELEMENT_BODY_MODAL": (params) => {
            const { elementName } = params
            return `Vous êtes sur le point de supprimer définitivement ${elementName} de votre bibliothèque.
             Cette action est irréversible, êtes-vous sûr de vouloir continuer ?`;
        },
        "REMOVE_ELEMENT_BUTTON": () => {
            return "Supprimer";
        },
        "USER_PROFILE_SELECT_DESCRIPTION": () => {
            return "Consulter et gérer les détails de votre profil";
        },
        "PAYMENT_DETAILS": () => {
            return "Modalités de paiement";
        },
        "PAYMENT_DETAILS_DESCRIPTION": () => {
            return "Consultation de l'historique des paiements et de tous les détails qui s'y rapportent";
        },
        "SUBSCRIPTION_DETAILS": () => {
            return "Abonnement";
        },
        "SUBSCRIPTION_DETAILS_DESCRIPTION": () => {
            return "Voir tous les détails de votre abonnement";
        },
        "USER_PROFILE_SELECT": () => {
            return "Paramètres du profil de l'utilisateur";
        },
        "ENTER_BUTTON": () => {
            return "Procéder";
        },
        "ACCOUNT_DETAILS": () => {
            return "Les détails de votre profil";
        },
        "ACCOUNT_DETAILS_DESC": () => {
            return "Vous pouvez y consulter les détails de votre compte. Vous pouvez également les modifier.";
        },
        "USER_SETTINGS": () => {
            return "Paramètres de l'utilisateur";
        },
        "USER_SETTINGS_DESC": () => {
            return "Vous pouvez y consulter toutes les informations relatives à votre compte et à vos abonnements.";
        },
        "SUBSCRIPTION_SETTINGS": () => {
            return "Détails de l'abonnement";
        },
        "SUBSCRIPTION_SETTINGS_DESC": () => {
            return "Vous pouvez ici consulter tous les détails de l'abonnement : compteurs, dates, etc.!"
        },
        "CANCEL": () => {
            return "Se désinscrire"
        },
        "DELETE_ACCOUNT": () => {
            return "Supprimer le compte"
        },
        "FIRST_NAME": () => {
            return ""
        },
        "ACCOUNT_TYPE": (params) => {
            var { accountType } = params;
            if (accountType === "Student") {
                accountType = "Étudiant";
            }
            else if (accountType === "Professor") {
                accountType = "Professeur";
            }
            return `${accountType}`;
        },
        "SAVE": () => {
            return "Sauvegarder"
        },
        "ACCOUNT_TYPE_LABEL": () => {
            return "Type de compte"
        },
        "DELETE_ACCOUNT_BODY": () => {
            return "Êtes-vous sûr de vouloir supprimer votre compte ? Cette action supprimera également tous les documents, les paquets de questions et les cartes heuristiques !"
        },
        "DELETE_ACCOUNT_TITLE": () => {
            return "Êtes-vous sûr ?"
        },
        "YES": () => {
            return "Oui"
        },
        "NO": () => {
            return "Non"
        },
        "TO_CHANGE_EMAIL": () => {
            return "Pour modifier votre adresse électronique, veuillez contacter le service d'assistance !"
        },
        "SUBSCRIPTION_PLAN": () => {
            return "Plan d'abonnement:"
        },
        "QUESTIONS_ASKED": () => {
            return "Questions posées:"
        },
        "TESTS_GENERATED": () => {
            return "Tests générés:"
        },
        "MINUTES_OF_SPEECH": () => {
            return "Procès-verbal utilisé pour l'enregistrement:"
        },
        "PAGES_UPLOADED": () => {
            return "Pages de documents téléchargés:"
        },

        "SUBSCRIPTION_EXPIRES": () => {
            return "Votre abonnement expire:"
        },
        "COUNTER_RESET": () => {
            return "Les compteurs seront remis à zéro le:"
        },
        "NO_DATA_AVAILABLE": () => {
            return "Pas de données disponibles"
        },
        "ORDER": () => {
            return "ACHETER"
        },
        "WEEKLY": () => {
            return "Hebdomadaire"
        },
        "MONTHLY": () => {
            return "Mensuel"
        },
        "YEARLY": () => {
            return "Annuel"
        },
        "DURATION_SUB_MONTHLY_PLURAL": (params) => {
            var { expirationUnit } = params;
            if (expirationUnit === "year") {
                expirationUnit = "années";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "month") {
                expirationUnit = "mois";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "week") {
                expirationUnit = "semaines";
                return `${expirationUnit}`;
            }
            return `${expirationUnit}`;
        },

        "DURATION_SUB": (params) => {
            var { expirationUnit } = params;
            if (expirationUnit === "year") {
                expirationUnit = "année";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "month") {
                expirationUnit = "mois";
                return `${expirationUnit}`;
            }
            else if (expirationUnit === "week") {
                expirationUnit = "semaine";
                return `${expirationUnit}`;
            }
            return `${expirationUnit}`;
        },
        "CATALOGUE": () => {
            return `Catalogue d'abonnement`;
        },
        "CATALOGUE_HEADER": () => {
            return `Sélectionner l'abonnement`;
        },
        "CHECKOUT_HEADER": () => {
            return `Payer`;
        },
        "SELECT_METHOD": () => {
            return `Sélectionnez le mode de paiement ci-dessous:`;
        },
        "PROCEED_CHECKOUT": () => {
            return `Passer à la caisse`;
        },
        "LOGIN_PROCEED": () => {
            return `Vous devez être inscrit pour procéder au paiement !`;
        },
        "DONT_HAVE_ACC": () => {
            return `Vous n'avez pas de compte ?`;
        },
        "DONT_HAVE_ACC2": () => {
            return `S'inscrire!`;
        },
        "SUBSCRIPTION_SUCCESSFUL": () => {
            return "Abonnement réussi!";
        },
        "SUBSCRIPTION_ERROR": () => {
            return "Échec de l'abonnement!";
        },
        "LIBRARY_WELCOME_MESSAGE_NUMEBR": (params) => {
            const { docs, folders } = params;
            const number = docs?.length || 0 + folders?.length || 0;

            if (number > 0) {
                if (number > 1) {
                    return (<span>Il y a   {number} documents dans votre bibliothèque</span>);
                }
                else {
                    return (<span> Il y a  {number} document dans votre bibliothèque</span>);
                }
            }
            else {
                return (<span>Votre bibliothèque est vide</span>);
            }
        },
        "WIP": () => {
            return `Nous travaillons`;
        },
        "PAYMENT_DETAILS_DESC": () => {
            return "Ici, vous pouvez voir et contrôler vos paiements";
        },
        "PAYPAL_CHECKOUT_ERROR": () => {
            return "Erreur pendant le processus de paiement, veuillez réessayer !";
        },
        "SUBSCRIPTION_IN_PROGRESS": () => {
            return "Veuillez patienter, l'abonnement est en cours...";
        },
        "LANGUAGE_NOT_FOUND": () => {
            return "Langue non trouvée";
        },
        "NO_PAYMENTS": () => {
            return "Pas de paiement trouvé";
        },
        "RETURN_TO_TEST": () => {
            return "Retour au test";
        },
        "TRAINING_HEADER": (params) => {
            const { type } = params;
            if (type === "document") {
                return "TESTS POUR VOTRE DOCUMENT";
            }
            if (type === "folder") {
                return "TEST POUR LE DOSSIER";
            }
        },
        "FILE_TYPE_NOT_SUPPORTED": () => {
            return "Type de fichier non pris en charge";
        },
        "CHANGE": () => {
            return "Changer";
        },
        "COUNTRY": () => {
            return "Pays";
        },
        "Q&A_HEADER": () => {
            return "Demandez et je vous répondrai";
        },
        "QUESTION": () => {
            return "QUESTIONS";
        },
        "QUESTION_TYPES": () => {
            return "Type de question";
        },
        "QUESTION_NUMBER": () => {
            return "Nombre de demandes";
        },
        "QUESTION_CHOICE": () => {
            return "Nombre de choix";
        },
        "YOUR_ANSWER": () => {
            return "VOTRE RÉPONSE";
        },
        "NOT_ANSWERED": () => {
            return "La question est restée sans réponse";
        },
        "CORRECT_ANSWER": () => {
            return "REPONSE CORRECTE ";
        },
        "CORRECT_ANSWER_SMALL": () => {
            return "Reponse correcte";
        },
        "PAGES_ANSWER": () => {
            return "Pages: ";
        },
        "QUESTIONS": () => {
            return "questions";
        },
        "RETURN_TO_TEST_NEW": () => {
            return "Répéter le test";
        },
        "DOCUMENT_PAGE_HEADER": () => {
            return "BOOSTER VOTRE DOCUMENT";
        },
        "BOOKS_TRANSFORM_SUPERPOWERS": () => {
            return "LE PARTENAIRE D'ÉTUDE IDÉAL";
        },
        "BOOKS_TRANSFORM_SUPERPOWERS_ANNA": () => {
            return "Avec ANNA, vous avez tout ce qu'il faut pour exceller dans vos études et atteindre vos objectifs plus rapidement. Elle est la tutrice qui vous fait interagir avec vos livres et vos documents, en tirant le meilleur parti de votre temps.";
        },
        "TRY_ANNA": () => {
            return "Essayez ANNA gratuitement";
        },
        "USED_BY_STUDENTS": () => {
            return "utilisé par plus de 10 000 étudiants";
        },
        "THEY_TALKED_ABOUT_US": () => {
            return "Ils ont déjà parlé de nous";
        },
        "PAGE_SELECTOR": () => {
            return "Sélectionner les pages";
        },
        "PAGES_RANGE_HELP": () => {
            return "Entrez un numéro de page (par exemple 5), une plage de pages (par exemple 5-10), une liste de pages (par exemple 1, 2, 3, 4, 5) ou un mélange (par exemple 5, 7-10, 12, 14).";
        },
        "PAGES_RANGE": () => {
            return "Choix des pages";
        },
        "SAVE_CHANGES": () => {
            return "Enregistrer les modifications";
        },
        "BOOST_STUDY_WITH_AI": () => {
            return "Améliorez vos études avec l'aide de l'IA";
        },
        "LANDING_FUNCTIONS_DESC": () => {
            return "ANNA rend l'étude amusante et interactive. Voici les trois principales caractéristiques qui vous aideront à mieux étudier:";
        },
        "LANDING_FUNCTIONS_DESC_CARD_1": () => {
            return (<>Discutez avec <strong>ANNA</strong> et posez-lui toutes vos questions sur les documents que vous téléchargez. Elle a toujours la réponse que vous cherchez !</>);
        },
        "LANDING_FUNCTIONS_DESC_CARD_2": () => {
            return (<>Créez une <strong>mindmap</strong> à partir de n'importe quel document. Visualisez les informations de manière claire et organisée pour faciliter votre apprentissage.</>);
        },
        "LANDING_FUNCTIONS_DESC_CARD_3": () => {
            return (<><strong>Testez vos connaissances</strong> avec les simulations d'examen d'ANNA. Obtenez un retour d'information dès maintenant et découvrez les points à améliorer, afin d'être toujours prêt pour les vrais tests.</>);
        },
        "LANDING_FUNCTIONS_DESC_CARD_4": () => {
            return (
                <><strong>ANNA</strong> crée pour vous des cartes rapides qui vous aideront à assimiler les concepts les plus importants grâce à des résumés impressionnants.</>);
        },
        "LANDING_STUDENTS_TITLE": () => {
            return (<>Pourquoi les étudiants aiment ANNA</>);
        },
        "LANDING_STUDENTS_SUBTITLE": () => {
            return (<>Découvrez une nouvelle façon d'étudier avec ANNA ! Grâce à nos outils basés sur l'IA, vous pouvez :</>);
        },
        "LANDING_STUDENTS_LIST_1": () => {
            return (<>Créer des cartes mentales qui simplifient des concepts complexes.</>);
        },
        "LANDING_STUDENTS_LIST_2": () => {
            return (<>Posez toutes vos questions sur le matériel d'étude et obtenez des réponses immédiates.</>);
        },
        "LANDING_STUDENTS_LIST_3": () => {
            return (<>Simulez des examens, des devoirs de classe, des questions ou identifiez simplement les points à améliorer.</>);
        },
        "LANDING_STUDENTS_LIST_4": () => {
            return (<>Téléchargez et apprenez à partir de livres, de documents, d'enregistrements audio et vidéo, d'images et bien plus encore !</>);
        },
        "LANDING_STICKER_TEACHER_TEXT": () => {
            return "pour les enseignants";
        },
        "LANDING_STICKER_STUDENT_TEXT": () => {
            return "Pour les étudiants";
        },
        "LANDING_TEACHER_TITLE": () => {
            return (<>Enseignants, voici votre super pouvoir</>);
        },
        "LANDING_TEACHER_SUBTITLE": () => {
            return (<>ANNA n'est pas seulement une aide pour les étudiants, mais aussi un allié précieux pour les enseignants. Grâce à nos outils, vous pouvez:</>);
        },
        "LANDING_TEACHER_LIST_1": () => {
            return (<>Téléchargez du matériel pédagogique et créez des activités interactives en quelques clics..</>);
        },
        "LANDING_TEACHER_LIST_2": () => {
            return (<>Suivre les progrès des étudiants et leur fournir un retour d'information personnalisé.</>);
        },
        "LANDING_TEACHER_LIST_3": () => {
            return (<>Gagnez du temps dans la préparation des cours grâce aux fonctions d'automatisation.</>);
        },
        "LANDING_SUBSCRIPTIONS_TITLE": () => {
            return (<>Étudier intelligemment sans se ruiner</>);
        },
        "LANDING_SUBSCRIPTIONS_SUBTITLE": () => {
            return (<>Avec ANNA, étudier intelligemment est à la portée de tous. Découvrez nos formules abordables et trouvez celle qui vous convient le mieux !</>);
        },
        "LANDING_QUESTIONS_TITLE": () => {
            return (<>Des questions ? Nous avons les réponses</>);
        },
        "LANDING_QUESTIONS_DESCRIPTION": () => {
            return (<>Si vous avez des questions, vous êtes au bon endroit ! Nous avons rassemblé les réponses aux questions les plus courantes pour vous aider à tirer le meilleur parti d'ANNA. Jetez un coup d'œil ci-dessous et découvrez comment Anna peut rendre votre étude encore plus efficace et amusante !</>);
        },

        "LANDING_QUESTIONS_LIST_0": () => {
            return (<>Comment fonctionne l'IA d'ANNA et comment peut-elle m'aider ?</>);
        },
        "LANDING_QUESTIONS_LIST_1": () => {
            return (<>L'utilisation d'ANNA est-elle sûre et quels sont les documents que je peux télécharger ?</>);
        },
        "LANDING_QUESTIONS_LIST_2": () => {
            return (<>L'IA peut-elle remplacer un enseignant ? Est-elle adaptée à tous les types d'examens et d'écoles ?</>);
        },


        "LANDING_QUESTIONS_LIST_0_ANSWER": () => {
            return (<>L'IA d'ANNA analyse les documents que vous téléchargez (livres, notes, diapositives, polycopiés)
                et répond à toutes les questions que vous vous posez sur les concepts, les dates, les définitions et les détails spécifiques.
                Elle vous aide à mieux comprendre votre matériel d'étude et organise les informations clés en cartes conceptuelles pour faciliter la compréhension
                et la mémorisation. Vous pouvez également créer des quiz et des tests personnalisés, en choisissant le nombre de questions,
                le type de réponse (ouverte ou fermée), et obtenir des corrections détaillées pour améliorer votre préparation.</>);
        },
        "LANDING_QUESTIONS_LIST_1_ANSWER": () => {
            return (<>Vous pouvez télécharger des livres, des notes, des diapositives, des polycopiés ou d'autres supports d'étude au format numérique.
                La sécurité de vos données est une priorité. La sécurité de vos données est une priorité :
                vos documents sont traités de manière confidentielle et ne sont pas partagés avec des tiers.
                ne sont pas partagés avec des tiers. Nous utilisons des mesures de sécurité avancées pour protéger vos Aucune
                compétence technique n'est requise pour utiliser l'outil : il est simple et intuitif. simple et intuitif.</>);
        },
        "LANDING_QUESTIONS_LIST_2_ANSWER": () => {
            return (<>L'IA est un outil d'aide à l'étude conçu pour améliorer votre méthode d'apprentissage en clarifiant les doutes,
                en résumant les concepts et en simulant des tests. Cependant, il ne remplace pas la valeur d'un professeur.
                Il est polyvalent et adapté à la préparation de tout type d'examen,
                écrit ou oral, et vous aide à optimiser votre étude et votre préparation d'une manière efficace. efficacement</>)
        },


        "CHOOSE_PLAN": () => {
            return (<>Choisir le plan</>);
        },
        "LANDING_MESSAGES_TITLE": () => {
            return (<>Pourquoi tout le monde parle d'ANNA</>);
        },
        "LANDING_MESSAGES_QUOTE1": () => {
            return (<>ANNA a transformé ma façon d'étudier ! Les cartes heuristiques et les quiz m'aident à mieux comprendre les concepts et à me préparer plus efficacement aux examens. Je ne pourrais plus m'en passer !</>);
        },
        "LANDING_MESSAGES_QUOTE1_AUTHOR": () => {
            return (<>Marco, 16 ans</>);
        },
        "LANDING_MESSAGES_QUOTE2": () => {
            return (<>Grâce à ANNA, je peux télécharger du matériel pédagogique et créer des activités interactives en quelques minutes. Le suivi des progrès des élèves est devenu beaucoup plus facile, et le retour d'information personnalisé a grandement amélioré leur apprentissage.</>);
        },
        "LANDING_MESSAGES_QUOTE2_AUTHOR": () => {
            return (<>Prof. Rossi, professeur de mathématiques</>);
        },
        "STUDENT": () => {
            return (<>Étudiant</>);
        },
        "TEACHER": () => {
            return (<>Professeur</>);
        },
        "DAILY": () => {
            return (<>Quotidiennement</>);
        },

        "PRIVACY_POLICY_TEXT": () => {
            return (
                <div className="container" style={{ height: '100%', textAlign: 'justify' }}>
                    <h1 className="test-boost">{getText()["PRIVACY_POLICY"]()}</h1>
                    <br></br>
                    <h3 className="test-boost">{getText()["PRIVACY_POLICY"]()}:</h3>
                    <p className='Text500__6'>UAB "Lab-H9" (hereinafter - the Data Controller) in this privacy policy (hereinafter - the Privacy Policy) sets the conditions for processing personal data using
                        this website managed by the Data Controller. The terms set out in the Privacy Policy apply every time you access the content and/or services we provide, regardless of the device (computer, mobile phone, tablet, TV, etc.) you are using.
                    </p>
                    <p className='Text500__7'>Details of the data controller:
                    </p>
                    <p className='Text500__7'>UAB "Lab-H9"
                    </p>
                    <p className='Text500__7'>Office address: Laisvės al. 82,
                    </p>
                    <p className='Text500__7'>Kaunas, LT-44250 Lietuva
                    </p>
                    <p className='Text500__7'>E-mail: <a href="mailto:anna@labh9.eu">anna@labh9.eu</a>
                    </p>
                    <p className='Text500__6'>It is very important that you read this Privacy Policy carefully, because every time you visit the <a href="https://www.labh9.ai" target='_blank' rel="noopener noreferrer">www.labh9.ai</a> website, you agree to the terms described in this Privacy Policy. If you do not agree to these terms, we regret to ask you not to visit our website, use our content and/or services. We confirm that the data of visitors to the website of the Data Controller will be collected in accordance with the requirements of the applicable legal acts of the European Union and the Republic of Lithuania and the instructions of the controlling authorities. All reasonable technical and administrative measures are in place to protect the data we collect about website visitors from loss, unauthorized use or alteration.
                        Individuals who are under the age of 16 may not submit any personal data through our website. If you are under the age of 16, you must obtain the consent of your parent or other legal guardian before submitting personal information.
                        The terms used in this privacy policy are understood as they are defined in the General Data Protection Regulation no. 2016/679.
                    </p>
                    <ul className='Text500__6'>
                        <li>name;
                        </li>
                        <li>surname;
                        </li>
                        <li>e-mail address.
                        </li>
                    </ul>
                    <h3 className="test-boost">Browsing data</h3>
                    <p>
                        We use local storage to manage security (at logon time) and to remember Your preferred language.
                    </p>
                    <p>
                        We will not share this information with anyone and it will only be stored on Your browser.
                    </p>
                    <p>
                        For access with third-party authentication systems (single-sign-on), such as Google and Facebook, it is possible that these systems use cookies and local storage and we refer You to the respective policies.
                    </p>
                    <ul className="Text500__5" style={{ textAlign: 'left' }}>
                        <li>
                            <a href="https://policies.google.com/technologies/cookies" target="_blank" rel='noopener noreferrer'>
                                Google Cookie Policy
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/privacy/policies/cookies" target="_blank" rel='noopener noreferrer'>
                                Facebook Cookie Policy
                            </a>
                        </li>
                    </ul>
                    <p>
                        To remind us that You have read the cookies policy, we will store a cookie on Your browser, which we will not share with anyone and which will only serve to prevent this message from appearing automatically every time You connect to the ANNA web.
                    </p>
                    <p>These are the local storage items and cookies managed by ANNA web site on its domain:</p>
                    <table className='cookies-table Text500__5 mb-3 mx-auto'>
                        <tbody>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Purpose</th>
                            </tr>
                            <tr>
                                <td>Cookie</td>
                                <td>cookieConsent</td>
                                <td>Remembering that You’ve read this cookie page</td>
                            </tr>
                            <tr>
                                <td>Local storage</td>
                                <td>_grecaptcha</td>
                                <td>Used for security purposes, during the registration, to prevent non-human (bot) interactions with the page</td>
                            </tr>
                            <tr>
                                <td>Local storage</td>
                                <td>UserAuth</td>
                                <td>Used for security purposes to store on Your browser Your identity data and token after You have logged in.</td>
                            </tr>
                            <tr>
                                <td>Cookie</td>
                                <td>active_language</td>
                                <td>Used to remember Your preferred language for the user interface</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3 className="test-boost">Third party cookies</h3>
                    <p>
                        The Site uses statistical cookies (for advertising and marketing purposes) to monitor visits to the Site.
                        <br />These cookies and their processing are subject to your consent and therefore will be used only if you accept.
                    </p>
                    <p>
                        If you accept third party cookies please refer to this providers and privacy disclosures policies:
                    </p>
                    <ul>
                        <li>Google Analytics
                            <ul>
                                <li><a href="https://www.google.com/policies/privacy/partners/">Google's Privacy & Terms</a></li>
                                <li><a href="https://support.google.com/analytics/answer/6004245">Safeguarding your data</a></li>
                            </ul>
                        </li>
                        <li>Google TAG manager
                            <ul>
                                <li><a href="https://support.google.com/tagmanager/answer/9323295?hl=en">Data privacy and security</a></li>
                            </ul>
                        </li>
                        <li>META PIXEL
                            <ul>
                                <li><a href="https://www.facebook.com/privacy/policy/">Meta Privacy Policy</a></li>
                            </ul>
                        </li>
                    </ul>
                    <h3 className="test-boost">How we use your information</h3>
                    <p className='Text500__6'>We may process your personal data for the following purposes:
                    </p>
                    <ul className='Text500__6'>
                        <li>For the purpose of direct marketing*, send newsletters, provide individually tailored advertising and send messages, evaluate and analyze our market, customers, products and services (including collecting your opinion about products and services and customer organization of surveys).
                        </li>
                        <li>To administer the website;
                        </li>
                        <li>To improve your browsing experience by adapting this website to your personal needs;
                        </li>
                        <li>In other ways with your consent*
                        </li>
                        <p className='Text500__6'><i>*We inform you that you have the right to disagree or withdraw your consent at any time to process your data for the above purposes marked with an asterisk.</i>
                        </p>
                    </ul>
                    <h3 className="test-boost">Other information we collect</h3>
                    <p className='Text500__6'>We may also collect other information about you, your device or your use of our website content with your consent.</p>
                    <p className='Text500__6'>We undertake not to transfer your personal data to any unrelated third parties, except in the following cases:
                    </p>
                    <ul className='Text500__6'>
                        <li>If there is consent to the disclosure of personal data.
                        </li>
                        <li>To law enforcement institutions in accordance with the procedure provided by the legal acts of the Republic of Lithuania.
                        </li>
                    </ul>
                    <h3 className="test-boost">Your rights
                    </h3>
                    <ul className='Text500__6'>
                        <li>To know (to be informed) about the processing of your data (right to know);
                        </li>
                        <li>Familiarize yourself with your data and how they are processed (right of access);
                        </li>
                        <li>Demand correction or, taking into account the purposes of personal data processing, to supplement incomplete personal data (right to correction);
                        </li>
                        <li>To destroy your data or stop the processing of your data (except storage) (right to destruction and right to be forgotten);
                        </li>
                        <li>Demand that the Personal Data Controller restricts the processing of personal data for one of the legitimate reasons (right to restriction);
                        </li>
                        <li>You have the right to transfer data (right to transfer);
                        </li>
                        <li>Opt-out to the processing of personal data when this data is processed or intended to be processed for direct marketing purposes, including profiling as far as it relates to such direct marketing.
                        </li>
                    </ul>
                    <p className='Text500__6'>If you no longer want your personal data to be processed for the purpose of direct marketing, you can write to at the email address <a href="mailto:anna@labh9.eu">anna@labh9.eu</a> and do not agree to your personal data being processed for the purpose of direct marketing.
                        You can submit any request or instruction related to the processing of personal data to the Data Controller by e-mail. to the email address <a href="mailto:anna@labh9.eu">anna@labh9.eu</a>. Upon receiving such a request or instruction, the data controller shall, no later than within one month from the date of the request, provide a response and perform the actions specified in the request or refuse to perform them. If necessary, the specified period can be extended by another two months, depending on the complexity and number of requests. In this case, within one month from the date of receipt of the request, the Data Controller informs the subject of such an extension, together with the reasons for the delay.
                    </p>
                    <h3 className="test-boost">Third-party websites, services and products on our websites
                    </h3>
                    <p className='Text500__6'>The Data Controller's website may contain third-party banners, links to their websites and services over which the Data Controller has no control. The data controller is not responsible for the security and privacy of information collected by third parties. You must read the privacy statements that apply to third-party websites and services you use.
                    </p>

                    <p>If you want to delete all your data you can delete your account in the user profile page, and our system automatically will remove all the personal information</p>

                </div>


            );
        },

        "TERMS_AND_CONDITIONS": () => {
            return "Conditions générales d'utilisation"
        },

        "TERMS_AND_CONDITIONS_TEXT": () => {
            return (
                <div className="container" style={{ height: '100%', textAlign: 'justify' }}>
                    <h1 className="test-boost">{getText()["TERMS_AND_CONDITIONS"]()}</h1>
                    <br></br>
                    <h3 className="test-boost">{getText()["TERMS_AND_CONDITIONS"]()}:</h3>
                    <p className='Text500__6'>These Terms of Use apply to the use of the Platform of Lab-H9 (UAB Lab-H9). Please read these Terms of Use carefully in order to ensure that you are aware of your rights and obligations when using the Platform. You can download and print this document.
                    </p>
                    <p className='Text500__6'>These Terms of Use <i>inter alia</i>, describe the following aspects of the relationship between you and Lab-H9:
                    </p>
                    <ul className='Text500__6'>
                        <li>The provision of Study Material (article 7) and creating a Profile (article 5);</li>
                        <li>The functionalities of the Platform (articles 3- 6);</li>
                        <li>The use of the AI Summaries, Mindmaps and Q&A functionality (article 8);</li>
                        <li>The use of the Group Chats functionality (article 9);</li>
                        <li>The notice and take down procedure (article 14);</li>
                        <li>The limitation of liability of Lab-H9(article 15).</li>
                    </ul>
                    <h3 className="test-boost">Article 1. Definitions</h3>
                    <p className='Text500__6'>In these Terms of Use, the terms listed below have the following meaning:
                    </p>
                    <p className='Text500__6'>1.1. <strong>AI: </strong>software that uses artificial intelligence techniques to generate Summaries, Mindmaps and answers and tests in reference to documents uploaded by the user                </p>
                    <p className='Text500__6'>1.2. <strong>AI Answers:</strong> all the answers and instructions that AI generates and that are made accessible to the user via dedicated section on the Platform, in response to questions submitted by the user.</p>
                    <p className='Text500__6'>1.3. <strong>AI Q&A:</strong> a separate section on the Platform, which enables User to submit questions in reference to documents earlier uploaded, which are then answered by AI.</p>
                    <p className='Text500__6'>1.4. <strong>Mindmaps:</strong> a separate section on the Platform, which enables User to get a mindmap in reference to documents earlier uploaded, which are generated by AI </p>
                    <p className='Text500__6'>1.5. <strong>Test/Quiz:</strong> a separate section on the Platform, which enables User to test their learing skill to a specific document. All the questions are generated by AI</p>

                    <p className='Text500__6'>1.6. <strong>Information:</strong> all information placed on the Platform by Lab-H9 itself, including – but not limited to – the layout and look and feel of the Platform, the logos and trademarks as well as texts. The Information does not include Study Materials.</p>

                    <p className='Text500__6'>1.7. <strong>Intellectual Property Rights:</strong> any and all intellectual property rights and associated rights such as copyrights, trademark rights, patent rights, model rights, trade name rights, database rights and neighbouring rights as well as the rights to know-how and one-line performances.</p>
                    <p className='Text500__6'>1.8. <strong>Platform:</strong> the online environment developed and offered by Lab-H9, which enables User from around the world to upload their documents and interact with them through an AI model. The Platform contains a separate AI, Summaries, Q&A, mindmap and Test sections  The Platform can be accessed through the websites <a href="https://anna.labh9.eu" target='_blank' rel="noopener noreferrer">anna.labh9.eu;</a> including all underlying domains and web pages, as well as mobile versions of the website and the Lab-H9 application for tablets and smartphones (“App”).
                    </p>
                    <p className='Text500__6'>1.9. <strong>Premium Account: </strong>a subscription that gives User access to the most functionalities on the Platform, as well as to specific features </p>
                    <p className='Text500__6'>1.10. <strong>Profile: </strong>an individual section created and maintained by you when you register for the Platform.</p>
                    <p className='Text500__6'>1.11. <strong>USER: </strong>a User of the Platform.</p>
                    <p className='Text500__6'>1.12. <strong>Documents:</strong> all the study documents, summaries, notes, assignments, books and other materials that are uploaded by User in his own account.</p>
                    <p className='Text500__6'>1.13. <strong>Terms of Use:</strong> these Terms of Use</p>
                    <h3 className="test-boost">Article 2. Applicability</h3>
                    <p className='Text500__6'>2.1. These Terms of Use govern every use of the Platform. By using the Platform, you accept these Terms of Use. The applicability of any other terms and conditions is excluded.</p>
                    <p className='Text500__6'>2.2. Lab-H9 is entitled to amend or supplement these Terms of Use. The most up-to-date Terms of Use can be found on the Platform. In the event of an amendment or supplementation that significantly affect your rights or obligations, Lab-H9 will bring these changes to your attention during the use of the Platform.</p>
                    <p className='Text500__6'>2.3. In the event that you continue the use of the Platform after these Terms of Use have been amended or supplemented, you thereby irrevocably accept the amended or supplemented Terms of Use. If you do not agree with the amended or supplemented Terms of Use, the only option you have is to no longer use the Platform and to terminate your Profile.
                    </p>
                    <h3 className="test-boost">Article 3. Availability</h3>
                    <p className='Text500__6'>3.1. You accept that the Platform contains only the functionalities and other characteristics as found on the Platform at the time of use ("as is basis"). Lab-H9 expressly excludes any explicit and tacit guarantees, undertakings and warranties of any nature whatsoever including, but not limited to, with regard to the quality, safety, lawfulness, integrity and accuracy of the Platform, unless explicitly stated otherwise in these Terms of Use.</p>
                    <p className='Text500__6'>3.2. You accept that the functionalities of the Platform may change.
                    </p>
                    <p className='Text500__6'>3.3. Lab-H9 is entitled to put the Platform (temporarily) out of service and/or to reduce the use of it without any prior notification and without being obliged to pay compensation to you, if in the opinion of Lab-H9 this is necessary, for instance, for maintenance of the Platform.
                    </p>
                    <p className='Text500__6'>3.4. Lab-H9 does not guarantee that the Platform or any part thereof will be accessible at all times and without any interruptions or failures. Failures in the Platform can occur as a result of failures in the internet or phone connection or by viruses or faults/defects. Lab-H9 is not liable or obliged to User to pay compensation in any way whatsoever for any damage resulting or arising from the website being (temporarily) unavailable.
                    </p>
                    <p className='Text500__6'>3.5. Lab-H9 is at any time entitled to change, alter or shut down the Platform without becoming liable to you in any way. If you do not agree with the changes and/or amendments made by Lab-H9, your only remedy is to cease the use of the Platform and to delete your Profile.
                    </p>
                    <h3 className="test-boost">Article 4. The Platform </h3>
                    <p className='Text500__6'>4.1. Lab-H9 offers a Platform where User can upload their Materials , such as documents essay, books and so forth and interact with them using AI. The interaction with the AI generates summaries, mindmaps, Q&A and test  8). Lab-H9’s mission is to empower User to excel at their studies by providing the best tools to learn more efficiently.
                    </p>
                    <p className='Text500__6'>4.2. The usage of the platform is based on a “freemium” model.
                    </p>
                    <p className='Text500__6'>4.3. Some Premium functions are only available for User with a Premium Account. Certain functionalities and features of the Platform require a Premium Account.
                    </p>
                    <p className='Text500__6'>4.4. The Platform may contain links to third party websites that are not owned or controlled by Lab-H9. Lab-H9 has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites.
                    </p>
                    <h3 className="test-boost">Article 5. Profile</h3>
                    <p className='Text500__6'>5.1. In order to access to Premium functionalities you have to create a Profile in the way described on the Platform. When creating a Profile, you must give an email address and password which will allow you to access the Platform and your Profile. You can also register using your existing Facebook or Google-account.
                    </p>
                    <p className='Text500__6'>5.2. You are only allowed to use the Platform when aged sixteen (16) or older. In the event that you have not yet reached the age of sixteen (16), you accept that you need the consent of your legal representative(s) (your guardian or (one of) your parents) before creating a Profile and/or taking out a subscription.

                    </p>
                    <p className='Text500__6'>5.3. You are responsible for maintaining secrecy with regard to your registration details. As soon as you know or have reason to assume that your username and/or password has come into the hands of unauthorized persons, you must inform Lab-H9 of this, without prejudice to your own obligation to take immediate effective measures yourself such as changing your password. You accept and acknowledge that you are at all times responsible and liable for the use of the Platform by third parties via your Profile.
                    </p>
                    <p className='Text500__6'>5.4. The personal data you provide during the registration process will be processed in accordance with Lab-H9's Privacy Policy Statement.
                    </p>
                    <p className='Text500__6'>5.5. You acknowledge and agree that by creating a Profile your user name, university and (if provided) profile picture are visible to other User if you post Study Materials and/or place comments, unless you choose to post Study Materials and/or partake in Study Communities anonymously.
                    </p>
                    <p className='Text500__6'>5.6 You acknowledge and confirm that you have all the rights on the document you upload and interact with on the platform
                    </p>
                    <h3 className="test-boost">Article 6. Premium Account</h3>
                    <p className='Text500__6'>6.1. User can obtain Premium access by taking out a dedicated subscription. The prices are communicated on the Platform.
                    </p>
                    <p className='Text500__6'>6.2. Payment is due in the manner described on the Platform.
                    </p>
                    <p className='Text500__6'>6.3. Lab-H9 offers a 7 days free trial subscription, which allows you to use Premium functionalities available on the Platform.. After 7 days, your trial subscription automatically extends into a weekly full Premium subscription, unless you cancel the subscription prior to that date. You can unsubscribe at any time via your Profile and no deductions will be made. After unsubscribing, you will retain access for the remainder of the trial period.
                    </p>
                    <p className='Text500__6'>6.4. It is possible that Lab-H9 offers longer/shorter free trial subscriptions in specific circumstances, such as actions for study societies. The applicable conditions will then be communicated by Lab-H9.
                    </p>
                    <p className='Text500__6'>6.5. Lab-H9 may introduce or change the subscription fees from time to time, for which we will give you advance notice. If your current subscription is still running, the price will remain in force for the fixed term. If you do not agree with the price change, you must cancel your subscription by the end of the then-current subscription term. If you do not cancel your subscription after the price change goes into effect, you agree to pay the changed price.
                    </p>
                    <p className='Text500__6'>6.6. Lab-H9 may suspend or cancel your Premium Account if a payment is not successfully settled.
                    </p>
                    <p className='Text500__6'>6.7. The subscription period will be renewed automatically for the selected subscription period, unless you have cancelled your subscription on time (before the last day of your subscription). In case your subscription is automatically renewed, you have the right to cancel the subscription at any time after such renewal with a cancellation term of one (1) month. In case of cancellation you may request reimbursement of part of the subscription fee. This means that you pay for the period until the cancellation enters into force, based on the standard monthly fee.
                    </p>
                    <h3 className="test-boost">Article 7. Documents/Materials           </h3>
                    <p className='Text500__6'>7.1. By means of the Platform User can upload their documents and material to be used for the learning process.

                    </p>
                    <p className='Text500__6'>7.2. You have the possibility to create folders, containing Study Materials selected by you relating to a certain course or subject. Folders or single documents are visible only to the User. Nothing will be shared with other users
                    </p>
                    <p className='Text500__6'>7.3. You understand and accept that you are responsible and accountable for Material you upload on the Platform. Lab-H9 is not liable to you or any third party for any damages arising out of or in relation to the Material uploaded or used by you within the Platform.
                    </p>
                    <p className='Text500__6'>7.4. You understand and accept that Lab-H9does not, and is under no obligation to, check or (re)view your Material. Lab-H9 only conducts an automated (quality) check to verify whether the Study Material contains sufficient texts and/or pages. Lab-H9has no knowledge of and/or influence on or involvement with the Materials uploaded by User.
                    </p>
                    <p className='Text500__6'>7.5. Lab-H9 reserves the right to convert the Materials you uploaded to another format and to automatically insert a watermark and/or advertisements in the materials.
                    </p>
                    <p className='Text500__6'>7.6. If you can delete your document/material at any time. The action cannot be reversed.
                    </p>
                    <p className='Text500__6'>7.7. Lab-H9 retains the right to decline or remove Material from the Platform, without accepting any responsibility or liability for its contents, without being held liable for any damage and without being obliged to pay any compensation.
                    </p>
                    <h3 className="test-boost">Article 8. AI Summaries, Mindmap, Q&A and Test/Quiz            </h3>
                    <p className='Text500__6'>8.1. By means of the separate AI Summaries, Mindmap, Q&A and Test/Quiz section on the Platform, User can interact with the uploaded document and answered by AI. The AI Answers will be available for User via a database in specific section on the Platform.
                    </p>
                    <p className='Text500__6'>8.2. To interact with all the sections, you will need to create a Profile.
                    </p>
                    <p className='Text500__6'>8.3. You accept and agree Lab-H9 solely facilitates the functionality, which enables User to use AI for their Studies.
                    </p>
                    <p className='Text500__6'>8.4. The Platform will specify the manner in which User can interact with the AI. Lab-H9 reserves the right to limit the availability of functionalities to User with a Premium Account even with Unlimited access or Unlimited usage. The limit, include – but it is not limited to a slow in AI reply or to a limit the number of questions can be submitted per day (or other time unit).
                    </p>
                    <p className='Text500__6'>8.5. AI will generate feedbacks within seconds. Lab-H9, however, does not guarantee that an the feedbacks are always provided within this time frame and does not accept liability in this regard.
                    </p>
                    <p className='Text500__6'>8.6. User agree that clear questions will generate a better AI output. Lab-H9 is not liable for any incorrect feedback
                    </p>
                    <p className='Text500__6'>8.7. The AI feedbacks are to be used as support in the learning process only, not as a substitute for a traditionl learning course. Lab-H9is not liable for the accuracy or completeness of the AI feedbacks.
                    </p>
                    <p className='Text500__6'>8.8. You understand and accept that you are responsible and accountable for use of feedbacks generated by AI. Lab-H9 is not liable to you or any third party for any damages arising out of, or in relation to them
                    </p>
                    <p className='Text500__6'>8.9. You understand and accept that Lab-H9 does not, and is under no obligation to, check or (re)view the AI feedbacks.
                    </p>
                    <h3 className="test-boost">Article 10. Your obligations and use of the Platform
                    </h3>
                    <p className='Text500__6'>10.1. You will only use the documents/materials that you will upload in the Platform
                    </p>
                    <p className='Text500__6'>10.2. You may not resell, distribute, or in any other way make profit of the Platform.
                    </p>
                    <p className='Text500__6'>10.3. You are the only responsible for documents you upload for instance (e-)books or any other material on the Platform.
                    </p>
                    <p className='Text500__6'>10.4. You are not permitted to use the Platform for a purpose as listed below, which is unrelated to the purpose of Lab-H9:
                    </p>
                    <ul className='Text500__6'>
                        <li>as your own work if it is not, as this is in breach of copyright. This is also plagiarism and constitutes academic misconduct at all academic institutions;
                        </li>
                        <li>which, at the discretion of Lab-H9, is discriminating or is otherwise deemed hurtful, offensive or inappropriate;
                        </li>
                        <li>in which personal data of others is made available, other than in compliance with applicable law;
                        </li>
                        <li>which contains scrapers, spiders, viruses, Trojan horses, worms, bots or other software which can damage or delete the Platform or make it unusable or inaccessible, or which or which is intended to circumvent technical protection measures of Lab-H9, the Website and/or the computer systems of Lab-H9;
                        </li>
                        <li>which infringes the rights of Lab-H9 and/or third parties, including but not limited to intellectual property rights or rights concerning the protection of privacy;
                        </li>
                        <li>which qualifies as or transmits unwanted or unsolicited material or content (spam);
                        </li>
                        <li>is contrary to these Terms of Use or any applicable laws or regulations, is in any other manner unlawful or which could prejudice the interests and reputation of lab-H9.
                        </li>
                    </ul>
                    <p className='Text500__6'>10.5. Lab-H9 will not be obliged to perform any payment, taxation, levy or deduction of any nature whatsoever to anyone as a result of the material uploaded by you, including to persons or parties whose work, model, brand or performance is included in the Materials, including to collective rights organizations.
                    </p>
                    <p className='Text500__6'>10.6. Lab-H9 reserves the right, without prior notice, to abridge, alter, refuse and/or remove Materials without this resulting in any liability.
                    </p>
                    <p className='Text500__6'>10.7. Lab-H9 is entitled to (temporarily) restrict or suspend your activities in connection with the Platform and/or to delete your Profile in the event you violate these Terms of Use.
                    </p>
                    <h3 className="test-boost">Article 11. Intellectual property rights
                    </h3>
                    <p className='Text500__6'>11.1. The Intellectual Property Rights in relation to the Platform, including the intellectual property rights with respect to the Information, are held by Lab-H9or its licensors. Nothing in the Terms of Use is intended to entail any transfer of Intellectual Property Rights to you.
                    </p>
                    <p className='Text500__6'>11.2. Under the conditions set out in these Terms of Use, Lab-H9 grants you a limited, personal, revocable, non-exclusive, non-sub licensable and non-transferable right to access and use the Platform, including the Information, insofar as is necessary for the use of the Platform.
                    </p>
                    <p className='Text500__6'>11.3. The collection of Materials or documents, that is collected by Lab-H9 and displayed on the Platform is a legally protected database. Lab-H9 is the producer of the database and therefore has the exclusive right to grant permission for i) the extraction or re-utilization of the entirety or a substantial part, from a quantitative and/or qualitative point of view, of the contents of the database and ii) the repeated and systematic extraction or re-utilization of non-substantial parts, from a quantitative and/or qualitative point of view, of the contents of the database, in so far as this is in conflict with the normal operation of the database or causes unjustified damage to lab-H9’s lawful interests. You may only request or reuse data from the database if and in so far as this is permitted under these Terms and Conditions.
                    </p>
                    <p className='Text500__6'>11.4. You retain all Intellectual Property Rights to your Material.
                    </p>
                    <p className='Text500__6'>11.5. You warrant that there are no (Intellectual Property) Rights of third parties which preclude you from use the Material uploaded on the Platform and/or providing the licence as mentioned in Article 11.4.
                    </p>
                    <p className='Text500__6'>11.6. You shall refrain from any act that infringes the Intellectual Property Rights of Lab-H9, including – but not limited to – the registration of domain names, trademarks or Google Adwords that are identical or similar to any object to which Lab-H9 holds Intellectual Property Rights, as well as the reproduction, modification, reverse engineering or publishing of the Platform, including the Information, for other purposes than those listed in the Terms of Use.
                    </p>
                    <h3 className="test-boost">Article 12. Indemnities and warranties
                    </h3>
                    <p className='Text500__6'>12.1. You guarantee that you will not use the Platform in a way that (i) infringes the rights of Lab-H9 or third parties, including – but not limited to – Intellectual Property Rights or rights in relation to the protection of privacy, (ii) is contrary to any current legislation or regulations, or (iii) is contrary to any provision in these Terms of Use.
                    </p>
                    <p className='Text500__6'>12.2. You are liable and indemnify Lab-H9 against any and all claims of third parties based on the allegation that the Material infringes any Intellectual Property Right or other right of third parties or is otherwise unlawful against third parties as well as against all claims resulting from your actions in violation with these Terms of Use. All costs incurred and damages suffered by Lab-H9 which are in any way related to the abovementioned claims will be remunerated by you.
                    </p>
                    <p className='Text500__6'>12.3. Lab-H9 does not guarantee (i) the Platform’s fitness for a particular purpose or that the Platform shall meet any of your requirements, (ii) that the Platform shall be available to you at all times and without interruptions, limitations or disturbances; (iii) that AI feedbacks on the Platform are accurate, up to date or complete.
                    </p>
                    <h3 className="test-boost">Article 13. Downloading and sharing Study Material
                    </h3>
                    <p className='Text500__6'>13.1. User with a Profile and a Premium Account have the ability to download some Materials from the Platform for personal use only. Your Profile will register what Material you have downloaded.
                    </p>
                    <p className='Text500__6'>13.2. Downloading Material is allowed, provided that you do not violate our Fair Use Policy. This policy states that you can download a maximum of 10 documents per day, with a maximum of 30 documents per month.
                    </p>
                    <p className='Text500__6'>13.3. You accept and acknowledge that sharing Materials generated by the AI is only permitted using a link to the Platform. You are not permitted to share any Materials that you have downloaded by other means that a link.
                    </p>
                    <h3 className="test-boost">Article 14. Notice of alleged unlawful material & repeat infringers
                    </h3>
                    <p className='Text500__6'>14.1. Lab-H9 respects the (Intellectual Property) Rights of others and takes the protection of (Intellectual Property) Rights seriously. Although Lab-H9 is not liable for any damages in connection with the (illegal) use of the Platform by User, Lab-H9 will, under the conditions as set out in this Article, remove Material(s), that is (are) infringing and/or unlawful and stop manifestly unlawful behaviour after receiving a notification that is sufficiently precise and adequately substantiated.
                    </p>
                    <p className='Text500__6'>14.2. If you claim that certain Material AI generated infringe on your (Intellectual Property) Rights, you can report this alleged infringement to Lab-H9 by mail. We will examine the notice and, if possible and necessary, take the measures to clear the issue.
                    </p>
                    <p className='Text500__6'>14.3. Lab-H9 reserves the right to not grant a request to remove Materials, or to stop an activity in case it has reasonable grounds to doubt the accuracy of the notification or in case a balancing of interest requires this. In such a situation, Lab-H9 can require a court order from a competent court, to demonstrate that the material or the activity is manifestly unlawful.
                    </p>
                    <p className='Text500__6'>14.4. Prior to removal of any alleged unlawful Material(s), Lab-H9 may request and the notifying party shall provide, additional information substantiating that the notifying party is indeed the rightsholder, and Lab-H9 may request and the notifying party shall provide, a signed copy of an indemnification declaration for LAB-H9.
                    </p>
                    <p className='Text500__6'>14.5. Lab-H9 will not be a party in a dispute between the person making the report and any third party arising out of or in connection with a notification.
                    </p>
                    <p className='Text500__6'>14.6. Lab-H9 has a policy of terminating accounts of User who repeatedly and intentionally infringe the Intellectual Property Rights of others.
                    </p>
                    <p className='Text500__6'>14.7. The notifying party indemnifies Lab-H9 against all claims of third parties in relation to the blocking or removal of Materials or the stopping of activities as a result of the notification. The indemnification includes all damages and costs Lab-H9 suffers, could suffer or which Lab-H9 must incur in relation to such a claim, including but not limited to the compensation of legal assistance.
                    </p>
                    <p className='Text500__6'>14.8. Lab-H9 respects and protects the privacy of those who provide notifications. All personal data that it receives as part of a notification will only be used to process the notice.
                    </p>
                    <p className='Text500__6'>14.9. Lab-H9 reserves the right to forward the notice to the user responsible for the Material to which the notice refers.
                    </p>
                    <h3 className="test-boost">Article 15. Liability
                    </h3>
                    <p className='Text500__6'>15.1. Lab-H9 accepts no liability whatsoever for damages resulting from the provision of the Platform, including damage resulting from or in connection with the use of the Platform or from wrongful acts or otherwise, in so far as this is permitted pursuant to mandatory law.
                    </p>
                    <p className='Text500__6'>15.2. In the event that Lab-H9– despite the above – is liable, this liability is limited to direct damages the user suffers as a result of a shortcoming or wrongful act that can be attributed to lab-H9. Direct damage only includes material damage to goods, reasonable costs incurred to prevent or limit direct damage and reasonable costs incurred to determine the cause of the damage. Lab-H9 shall in no event be liable for any special, incidental or consequential damages whatsoever (“indirect damages”), including – but not limited to – loss and/or damage of data (including  Material), loss of profits and lost sales, is excluded.
                    </p>
                    <p className='Text500__6'>15.3. Lab-H9’s liability is in any case limited to the highest of the following amounts: (i) the amount actually paid by the user as premium account, or (ii) € 500,00 (five hundred euros/00).
                    </p>
                    <p className='Text500__6'>15.4. The limitations and exclusions of liability mentioned in the preceding paragraphs of this article will lapse if and in so far as the damage is the result of intentional or willful recklessness on the part of Lab-H9 or its managers (“own actions”).
                    </p>
                    <h3 className="test-boost">Article 16. Force Majeure
                    </h3>
                    <p className='Text500__6'>16.1. In the case of force majeure, the non-fulfillment of the agreement between you and Lab-H9cannot be counted as an attributable failure and Lab-H9 is released from all contractual obligations and liability.
                    </p>
                    <p className='Text500__6'>16.2. Force majeure includes, but is not limited to, illness of employees and/or absence of personnel crucial for the provision of the Platform, disruptions of the electricity supply, fire, natural disasters, strikes, floods, failures to perform by third parties hired by Lab-H9, disturbances in the internet connection, hardware disruptions, disruptions in the (telecommunications) networks and other unforeseen circumstances.
                    </p>
                    <h3 className="test-boost">Article 17. Termination
                    </h3>
                    <p className='Text500__6'>17.1. You are entitled to discontinue your use of the Platform and to terminate your Profile.
                    </p>
                    <p className='Text500__6'>17.2. You accept and acknowledge that any Materials uploaded, or any output created and stored, by you beforehand, will be delated from the Platform.
                    </p>
                    <h3 className="test-boost">Article 18. Miscellaneous
                    </h3>
                    <p className='Text500__6'>18.1. These Terms of Use and the use of the Platform are governed by and construed in accordance with the laws of Lithuania
                    </p>
                    <p className='Text500__6'>18.2. Any and all disputes arising from or related to the Platform or the Terms of Use will be brought before the competent court in Vilnius, unless compulsory law mandates otherwise.
                    </p>
                    <p className='Text500__6'>18.3. If at any time any provision of these Terms of Use is or becomes illegal, void or invalid for any reason whatsoever, such invalidity shall not affect the validity of the remainder of the Terms of Use and such invalid provision shall be replaced by another provision which, being valid in all respects, shall have effect as close as possible to that of such replaced one
                    </p>
                    <p className='Text500__6'>18.4. Lab-H9 is entitled to assign any rights and obligations that result from these Terms of Use to third parties, in which case it will notify you. In the event that you cannot agree with the assignment, your only remedy is to cease the use of the Platform and delete your Profile.
                    </p>
                    <br></br>
                </div>
            );
        },

        "PLAN_DOESNT_INCLUDE_FOLDERS": () => {
            return ("Le plan ne comprend pas de dossiers");
        },
        "PLAN_DOESNT_INCLUDE_VOICE": () => {
            return ("Le plan ne comprend pas la transcription vocale");
        },
        "PLAN_DOESNT_INCLUDE_VIDEO": () => {
            return ("Le plan n'inclut pas la transcription vidéo")
        },
        "SUBSCRIPTION_REQUIRED": () => {
            return ("La taille maximale des données est atteinte !");
        },
        "GO_TO_CATALOGUE": () => {
            return ("Aller au catalogue");
        },
        "PAGES_THUMB_TITLE": () => {
            return "page";
        },
        "COUNTRY_NOT_FOUND": () => {
            return `Ajouter un pays à votre profil!`;
        },
        "CONTACT_US": () => {
            return `Contactez nous`;
        },
        "WRITE_EMAIL": () => {
            return `Courrier électronique`;
        },
        "LEAVE_MAIL_MESSAGE_HERE": () => {
            return `Ecrivez votre message ici`;
        },
        "OPS": () => {
            return `Opssss...`
        },
        "NAME_MANDATORY": () => {
            return `Vous devez saisir le nom pour continuer`
        },
        "SURNAME_MANDATORY": () => {
            return `Vous devez saisir votre nom de famille pour continuer`
        },
        "MAIL_MANDATORY": () => {
            return `Vous devez saisir votre e-mail pour continuer`
        },
        "TEXT_MANDATORY": () => {
            return `Vous ne pouvez pas envoyer un e-mail vide`
        },
        "INVALID_IMAGE": () => {
            return `Seules les images JPEG et PNG sont autorisées !`;
        },
        "STATUS": () => {
            return `État`;
        },
        "SUBSCRIPTION": () => {
            return `Produit`;
        },
        "AMOUNT": () => {
            return `Montant`;
        },
        "DATE": () => {
            return `Data`;
        },
        "UPLOAD_DOC_MOBILE_TITLE": () => {
            return `Télécharger un document`;
        },
        "UPLOAD_DOC_MOBILE_DESC": () => {
            return `Ajoutez un fichier pdf, ebook, word, powerpoint, excel ou texte.`;
        },
        "CREATE_FOLDER_MOBILE_TITLE": () => {
            return `Création d'un dossier`;
        },
        "CREATE_FOLDER_MOBILE_DESC": () => {
            return `Créez un dossier pour organiser vos documents`;
        },
        "UPLOAD_VIDEO_MOBILE_TITLE": () => {
            return `Télécharger une vidéo`;
        },
        "UPLOAD_VIDEO_MOBILE_DESC": () => {
            return `Utiliser la vidéo en direct ou envoyer un fichier vidéo`;
        },

        "SUBMIT_PICTURE_MOBILE_TITLE": () => {
            return `Scanner`;
        },
        "SUBMIT_PICTURE_MOBILE_DESC": () => {
            return `Scannez votre livre ou envoyez des images.`;
        },
        "UPLOAD_SPEECH_MOBILE_TITLE": () => {
            return `Enregistrer un fichier audio`;
        },
        "UPLOAD_SPEECH_MOBILE_DESC": () => {
            return `Enregistrer un fichier audio `;
        },

        "UPLOAD_SPEECH_DESKTOP_DESC": () => {
            return `Utilisez votre voix ou envoyez un fichier audio.`;
        },
        "COOKIE_POLICY_WELCOME": () => {
            return "Bienvenue à ANNA";
        },
        "LEARN_MORE": () => {
            return "En savoir plus";
        },
        "I_UNDERSTAND": () => {
            return "Sauvegarder les préférences";
        },
        "NECESSARY_COOKIES": () => {
            return `Cookies nécessaires`;
        },
        "THIRD_PARTY_COOKIES": () => {
            return `Cookies publicitaires de tiers`;
        },
        "COOKIE_POLICY_TEXT": () => {
            return (<div className="gdpr-modal-list Text500__5" style={{ textAlign: 'justify' }}>
                <p>ANNA est fabriqué par UAB LAB-H9 Lithuania, Laisvės al. 82, Kaunas,  <a href="mailto:anna@labh9.eu">anna@labh9.eu.</a></p>
                <p>
                    Nous utilisons le stockage local pour gérer la sécurité (lors de la connexion) et pour mémoriser votre langue préférée.
                </p>
                <p>
                    Nous ne partagerons pas ces données stockées dans votre navigateur avec qui que ce soit.               </p>
                <p>
                    Pour l'accès avec des systèmes d'authentification de tiers (single-sign-on), tels que Google et FaceBook, ces systèmes peuvent utiliser des cookies et un stockage local, pour lesquels nous vous renvoyons à leurs politiques respectives.
                </p>
                <ul className="Text500__5" style={{ textAlign: 'left' }}>
                    <li>
                        <a href="https://policies.google.com/technologies/cookies" target="_blank" rel='noopener noreferrer'>
                            Google Cookie Policy
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/privacy/policies/cookies" target="_blank" rel='noopener noreferrer'>
                            Facebook Cookie Policy
                        </a>
                    </li>
                </ul>
                <p>
                    Nous ne gérons ni ne stockons les cookies de tiers, mais il est possible que, pour des raisons de sécurité ou juridiques, des jetons d'authentification soient stockés dans notre base de données.
                </p>
                <p>
                    Pour nous rappeler que vous avez déjà lu cette page et pour éviter de vous la montrer à chaque fois que vous vous connectez, nous utiliserons un cookie.                </p>
            </div>
            )
        },

        "COOKIE_POLICY_TABLE": () => {
            return (<div className="gdpr-more-info Text500__5" style={{ alignItems: 'center' }}>
                <p>These are the local storage items and cookies managed by ANNA web site on its domain:</p>
                <table className='cookies-table Text500__5 mb-3 mx-auto'>
                    <tbody>
                        <tr>
                            <th>Type</th>
                            <th>Name</th>
                            <th>Purpose</th>
                        </tr>
                        <tr>
                            <td>Cookie</td>
                            <td>cookieConsent</td>
                            <td>Remembering that You’ve read this cookie page</td>
                        </tr>
                        <tr>
                            <td>Local storage</td>
                            <td>_grecaptcha</td>
                            <td>Used for security purposes, during the registration, to prevent non-human (bot) interactions with the page</td>
                        </tr>
                        <tr>
                            <td>Local storage</td>
                            <td>UserAuth</td>
                            <td>Used for security purposes to store on Your browser Your identity data and token after You have logged in.</td>
                        </tr>
                        <tr>
                            <td>Cookie</td>
                            <td>active_language</td>
                            <td>Used to remember Your preferred language for the user interface</td>
                        </tr>
                    </tbody>
                </table>
                <h3 className="test-boost">Third party cookies</h3>
                <p>
                    The Site uses statistical cookies (for advertising and marketing purposes) to monitor visits to the Site.
                    <br />These cookies and their processing are subject to your consent and therefore will be used only if you accept.
                </p>
                <p>
                    If you accept third party cookies please refer to this providers and privacy disclosures policies:
                </p>
                <ul>
                    <li>Google Analytics
                        <ul>
                            <li><a href="https://www.google.com/policies/privacy/partners/">Google's Privacy & Terms</a></li>
                            <li><a href="https://support.google.com/analytics/answer/6004245">Safeguarding your data</a></li>
                        </ul>
                    </li>
                    <li>Google TAG manager
                        <ul>
                            <li><a href="https://support.google.com/tagmanager/answer/9323295?hl=en">Data privacy and security</a></li>
                        </ul>
                    </li>
                    <li>META PIXEL
                        <ul>
                            <li><a href="https://www.facebook.com/privacy/policy/">Meta Privacy Policy</a></li>
                        </ul>
                    </li>
                </ul>
            </div>);
        },

        "COOKIE_POLICY_CONTACT": () => {
            return (<div className="Text500__5" style={{ textAlign: 'justify' }}>
                <p>Pour toute question <a href="mailto:info@labh9.eu">info@labh9.eu</a></p>
            </div>)
        },

        "UNABLE_TO_TRANSCRIBE": () => {
            return `Impossible de transcrire les images fournies`;
        },
        "APPROVAL": () => {
            return `Approbation`;
        },
        "NO_INFORMATION_CLASS": () => {
            return `Aucune information n'a été fournie sur la classe`;
        },
        "PRIVACY_POLICY_REGISTER": () => {
            return (
                <p className='Text500__6'>e <strong><a href="/it/privacy_policy" target='_blank' rel="noopener noreferrer" >privacy</a></strong></p>
            );
        },
        "PRIVACY_POLICY": () => {
            return "Privacy";
        },
        "COUNTRY_REQUIRED": () => {
            return `Vous devez choisir un pays`;
        },
        "EMAIL_SENT": () => {
            return `Un e-mail a été envoyé pour modifier le mot de passe, vérifiez votre boîte de réception.`;
        },

        "ENTER_EMAIL_FORGOT_PASSWORD": () => {
            return (<p>Saisissez ,<strong>l'adresse électronique</strong> associée à votre compte et nous vous enverrons un lien pour réinitialiser votre <strong>mot de passe</strong>.</p>);
        },
        "PASSWORD_RESET": () => {
            return `Récupération du mot de passe`;
        },
        "REQUEST": () => {
            return `Envoyer la demande`;
        },
        "REQ_PROFESSOR": () => {
            return `Êtes-vous sûr de vouloir demander une mise à jour de votre compte d'utilisateur ? Une procédure de vérification sera activée et vous pourrez être contacté par notre back-office.`;
        },
        "CONFIRM_ROLE_PROFESSOR": () => {
            return `Passer à un compte enseignant`;
        },
        "PROMO_CODE": () => {
            return `Code promo`;
        },

        "ERROR_CREATE_TEST": () => {
            return "Sélectionnez tous les paramètres !";
        },

        // About anna screen
        "ABOUTANNA": () => {
            return `À propos d'ANNA`;
        },

        "ABOUTANNA_WHOISANNA": () => {
            return `Qui est ANNA ?`;
        },

        "ABOUTANNA_WHOISANNA_ANSWER": () => {
            return (
                <p>
                    <strong>ANNA</strong> est un tuteur numérique basé sur des technologies avancées d'intelligence artificielle.

                    <br></br>

                    <strong>ANNA</strong> peut vous aider à étudier en optimisant votre temps et en simplifiant l'apprentissage grâce à des cartes heuristiques, des chats avancés et des simulations d'examen.
                    <br></br>

                    <strong>ANNA</strong> utilise uniquement vos propres sources (livres, vidéos, documents) pour vous aider à étudier, il est donc fiable et sûr.
                    <br></br>

                    <strong>ANNA</strong> est un compagnon d'étude idéal et vous pouvez l'essayer gratuitement.

                </p>
            );
        },
        "ABOUTANNA_HOWITWORKS_TITLE": () => {
            return `Comment fonctionne ANNA ?`;
        },
        "ABOUTANNA_HOWITWORKS": () => {
            return `Comment cela fonctionne-t-il ?`;
        },

        "ABOUTANNA_HOWITWORKS_ANSWER": () => {
            return (
                <div>
                    <p>Choisissez votre langue préférée</p>

                    <br></br>
                    <div className='image_div'>
                        <img className="about_image" alt="language selector" src={changeLanguage}></img>
                    </div>
                    <p>Ne vous inquiétez pas, vous pouvez le modifier à tout moment et le nombre de langues disponibles augmentera au fil du temps.

                        <br></br>

                        Téléchargez vos documents sur <strong>ANNA</strong>. Vous pouvez utiliser n'importe quel type de fichier, pdf, word, ebook, excel, power point ou des fichiers multimédias tels que des images, des vidéos ou des fichiers audio.                    </p>

                    <div className='image_div'>
                        <img className="about_image" alt="File Uploading" src={fileUpload}></img>
                    </div>

                    <p> Vous pouvez prendre des photos de vos livres ou de vos notes
                    </p>

                    <div className='image_div'>
                        <img className="about_image" alt="" ImageUploading src={imgUpload}></img>
                    </div>


                    <p>ou enregistrer votre voix.
                    </p>

                    <div className='image_div'>
                        <img className="about_image" alt="Audio uploading" src={audioUpload}></img>
                    </div>

                    <p> Vous pouvez organiser votre contenu en dossiers ou extraire des documents uniquement les pages qui vous intéressent.
                    </p>

                    <div className='image_div'>
                        <img className="about_image" alt="Create folder" src={folderExample}></img>
                    </div>

                    <p>Ne vous souciez pas de la traduction, <strong>ANNA</strong> utilise toujours la langue de votre choix.
                    </p>

                </div>
            );
        },

        "ABOUTANNA_Q&A_TITLE": () => {
            return `Question & Answer`;
        },

        "WHATISQ&A": () => {
            return `Qu'est-ce que la fonction Question and answer?`;

        },

        "WHATISQ&A_ANSWER": () => {
            return (
                <p>
                    Grâce à cette fonction, vous pouvez exploiter la puissance de la   <strong> IA générative</strong> pour discuter avec
                    <strong>ANNA</strong> l'utilisation de vos propres documents comme base de connaissances.

                    <br></br>

                    <strong>ANNA</strong> ne se contente pas de répondre à vos questions.

                    <br></br>

                    A partir de vos propres fichiers (livres, notes, images, vidéo, audio), <strong>ANNA</strong> est capable de créer des résumés, d'extraire et de retraiter des informations, de produire des tableaux, de comparer des données ou de générer de nouveaux contenus en fonction de vos besoins.

                    <br></br>

                    Ne mettez pas de limites à ce que <strong>ANNA</strong> peut faire.


                </p>

            );
        },
        "MINDMAP_USE_QUESTION": () => {
            return `Qu'est-ce qu'une carte mentale ??
`;

        },

        "MINDMAP_USE_ANSWER": () => {
            return (
                <p>
                    Les cartes heuristiques sont un excellent outil pour étudier des sujets vastes ou complexes.
                    <br></br>

                    <strong>ANNA</strong> crée pour vous des mindmap de chaque document ou dossier, en organisant le contenu sous une forme graphique utile pour l'apprentissage rapide et la mémorisation des éléments clés.
                    <br></br>

                    Vous pouvez naviguer sur la mindmap en développant les nœuds et en affichant des résumés de chaque sujet.
                    <br></br>

                    Vous pouvez partager, imprimer et exporter votre mindmap en un seul clic.                    <div className='image_div'>
                        <img src={mindMap} alt="Mindmap" className="about_image"></img>
                    </div>
                </p>

            );

        },

        "HOW_TO_USE_TEST": () => {
            return `Comment fonctionnent les tests d'ANNA ?`;
        },

        "HOW_TO_USE_TEST_ANSWER": () => {
            return (
                <p>
                    Avec <strong>ANNA</strong>, vous pouvez simuler un examen pour vérifier l'état de vos connaissances.
                    <br></br>
                    Choisissez le document ou le dossier et créez un test d'auto-évaluation.
                    <br></br>
                    Vous pouvez personnaliser votre test en indiquant le nombre de questions et le type d'examen, avec des réponses fermées ou ouvertes.
                    <br></br>
                    Répondez aux questions et vérifiez le résultat. <strong>ANNA</strong> vous expliquera les erreurs éventuelles, en vous indiquant la bonne réponse et les pages de votre texte à relire.
                    <br></br>
                    Entraînez-vous avec le <strong>ANNA</strong> et se préparer pour le prochain devoir, la prochaine question ou le prochain examen.

                </p>

            )

        },
        "HOW_TRY_ANNA": () => {
            return `Comment puis-je essayer ANNA ?`;
        },

        "HOW_TRY_ANNA_ANSWER": () => {
            return (
                <p>
                    Vous pouvez essayer <strong>ANNA</strong> gratuitement. Il vous suffit de vous inscrire ou de vous connecter avec Google ou Facebook.
                    <br></br>
                    Vous pouvez l'essayer avec les cinq premières épreuves, qui comportent jusqu'à 50 questions et cinq tests.
                    <br></br>
                    Si <strong>ANNA</strong> répond à vos besoins, vous pouvez souscrire un abonnement hebdomadaire ou mensuel.
                </p>

            )
        },

        "HOW_MUCH": () => {
            return `Combien coûte ANNA ?`;
        },

        "HOW_MUCH_ANSWER": () => {
            return (
                <p>
                    <strong>ANNA</strong> est gratuit pour les cinq premiers documents
                    <br></br>
                    Vous disposez d'un maximum de 50 questions et de 5 tests dans le cadre de l'essai gratuit.
                    <br></br>
                    Vous pouvez consulter notre <a href='/catalogue' >catalogue</a> pour voir nos offres et comparer les différentes formules d'abonnement.
                    <br></br>

                    Vous pouvez acheter des abonnements en utilisant PayPal et les principales cartes de crédit.
                    <br></br>

                    Vous pouvez interrompre le renouvellement automatique à tout moment.
                    <br></br>

                    Les écoles et les enseignants peuvent bénéficier de politiques de réduction spéciales, contactez-nous pour plus de détails.
                </p>

            )
        },

        "ANNA_LAB": () => {
            return `Qu'est-ce que ANNA-LAB ?`;
        },

        "ANNA_LAB_ANSWER": () => {
            return (
                <p>
                    Si vous voulez être un bêta-testeur, <strong>ANNA-LAB</strong> est l'endroit idéal.
                    <br></br>
                    Vous pourrez tester les nouvelles fonctionnalités en cours de développement et interagir avec nos développeurs.

                </p>

            )
        },

        "NOACCOUNT": () => {
            return `Vous n'avez pas de compte ?`
        },

        "REGISTERFREE": () => {
            return `S'inscrire gratuitement`

        },
        "CANCEL_SUBSCRIPTION_MODAL": () => {
            return "Êtes-vous sûr de vouloir annuler votre abonnement ? Vous pourrez utiliser votre abonnement jusqu'à la date d'expiration."
        },
        "FILE_UPLOADED": () => {
            return "Le fichier a été téléchargé"
        },
        "FILE_UPLOADED_SUCCESS": () => {
            return "Document téléchargé avec succès"
        },
        "ERROR_ACCESSING_MICROPHONE": () => {
            return "Erreur lors de l'accès au microphone"
        },
        "FAILED_TO_GET_PAYMENTS": () => {
            return "Impossibilité d'obtenir des paiements"
        },
        "SUCCESS": () => {
            return "réussies";
        },

        "QUICKCARDS_SESSION_TITLE": () => {
            return `QuickCards`;
        },

        "QUICKCARDS_SESSION": () => {
            return `Je peux créer pour vous des cartes rapides qui vous aideront à assimiler les concepts les plus importants avec des résumés impressionnants.`;
        },

        "HOW_ANNA_WORK": () => {
            return `Voir comment ça marche`;
        },

        "UPLOAD_URL_MOBILE_TITLE": () => {
            return `Télécharger une page web `;
        },
        "UPLOAD_URL_MOBILE_DESC": () => {
            return `Donnez-moi l'URL de la page et j'obtiendrai tout le contenu.`;
        },
        "UPLOAD_URL_MOBILE_LABEL": () => {
            return `Adresse de la page à envoyer`;
        },
        "UPLOAD_URL_CHECK_MOBILE_LABEL": () => {
            return `Suivez les liens sur la page`;
        },
        "UPLOAD_URL_MOBILE_MODAL_DESC": () => {
            return `La page indiquée sera téléchargée et tout son contenu sera utilisé comme document dans votre bibliothèque.`;
        },
        "UPLOAD_URL_MOBILE_DOWNLOADING_DESC": () => {
            return `Je suis en train de télécharger votre page. Veuillez patienter...`
        },
        "BLOGS": () => {
            return `Blogs`;
        },
        "CONNECTING_CHAT_AI_ENGINE": () => {
            return `Votre chatbot ANNA dédié est lancé. Attendez quelques secondes.`;
        },
        "CONNECTED_CHAT_AI_ENGINE": () => {
            return `Votre chatbot ANNA est maintenant prêt.`;
        },
        "PUBLIC_CARDS_SCIENCE_TOPIC": () => {
            return `Science`;
        },
        "PUBLIC_CARDS_HISTORY_TOPIC": () => {
            return `Histoire`;
        },
        "PUBLIC_CARDS_ARTS_TOPIC": () => {
            return `Art`;
        },
        "PUBLIC_CARDS_SOCIETY_TOPIC": () => {
            return `Société`;
        },
        "PUBLIC_CARDS_SPORT_TOPIC": () => {
            return `Sport`;
        },
        "PUBLIC_CARDS_SOURCES": (params) => {
            const { sources } = params;

            return (<div><p>Cette page a été générée automatiquement par ANNA en accédant aux sources suivantes:</p>

                <ul>
                    {sources.map((s) => (<li>{s}</li>))}
                </ul>
                <p>Si vous souhaitez en générer davantage, <a href="/fr/login">inscrivez-vous gratuitement</a>.</p></div>);
        },
        "PUBLIC_CARDS_WANT_TO_TRY_MIND_MAPS": (params) => {
            const { id } = params;

            return (<>
                Ce résumé a été généré automatiquement par ANNA à l'aide des sources répertoriées en bas de page. Voulez-vous aussi essayer les cartes mentales ? <a href={`/it/public/mindmaps/${id}`}>Suivez ce lien</a>. Ou inscrivez-vous gratuitement et essayez toutes les fonctionnalités avec vos documents préférés.
            </>);
        },
        "PUBLIC_CARDS_INTRO": (params) => {
            return (<>
                <h5>Apprenez avec les quick cards d'ANNA.</h5>
                <p>De nouvelles cartes rapides sur des sujets d’intérêt sont ajoutées quotidiennement.
                    Si vous ne trouvez pas ce que vous cherchez, <a href="/fr" title="Connectez-vous à ANNA">créez vos cartes maintenant</a>.
                </p>
            </>);
        },
        "UNAUTHORIZED": (params) => {
            return `Action non autorisée`
        },
        "ARTICLE_SAVED": () => {
            return `L'article est enregistré correctement`
        },
        "SAVING_IN_PROGRESS": () => {
            return `Sauvegarde des données en cours`
        },
        "ARTICLE_NOT_FOUND": () => {
            return `Article non trouvé`
        },
        "MAKE_VISIBILE": () => {
            return `Rendre l'article visible`

        },        
        "BLOG_INTRO": () => {
            return `Restez à jour sur ANNA et nos solutions d'apprentissage basées sur l'IA!`

        }
    }
}

export { getText };