import * as labels from "../components/localized/labels";
import React from 'react';

import separator from '../assets/separator.svg';

function getBreadcrumb(params) {
    const language = labels.getLanguage();
    let _home = `/${language}`;

    //const upperCaseLanguage = language.toUpperCase();
    const { page, id = null, id2 = null, name = null, topicName = null, topicId = null, folderId,
        professorclassroomId, classroomId, classroomName, packageId
    } = params;

    function ellipsis(text, maxSize) {
        let _output = text;
        if (_output && _output.length > maxSize) {
            _output = `${_output.substring(0, maxSize - 1)}...`;
        }
        return _output;
    }

    switch (page) {
        case "home": {
            return (
                <span>
                    <a href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                        {labels.getText()["BREADCRUMB_HOME"]()}
                    </a>
                </span>
            );
        }

        case "document": {
            let _homeLink = _home;
            let _documentLink = (`/${language}/document/${id}`);
            if (professorclassroomId) {
                _homeLink = (`/${language}/professor/library/classroom/${professorclassroomId}`);
                _documentLink = (`/${language}/professor/document/classroom/${id}/${professorclassroomId}`);
            } else if (classroomId) {
                _homeLink = (`/${language}/library/classroom/${classroomId}`);
                _documentLink = (`/${language}/document/classroom/${id}/${classroomId}`);

            }


            return (
                <div className="breadcrumb">
                    <a className="then-small-link" href={_homeLink} title={labels.getText()["BREADCRUMB_HOME"]()}>
                        {labels.getText()["BREADCRUMB_HOME"]()}
                    </a>
                    <img src={separator}></img>
                    <a className="then-small-link" href={_documentLink} title={name} >
                        {ellipsis(labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name }), 25)}
                    </a>
                </div>
            );
        }
        case "blog": {
            const { id, title } = params;
            let _homeLink = _home;            
            let _blogLink = (`/${language}/blog`);

            return (
                <div className="breadcrumb">
                    <a className="then-small-link" href={_homeLink} title={labels.getText()["BREADCRUMB_HOME"]()}>
                        {labels.getText()["BREADCRUMB_HOME"]()}
                    </a>
                    <img src={separator}></img>
                    <a className="then-small-link" href={`${_blogLink}`} title={"blog"} >
                        Blog
                    </a>
                    {
                        id ? (
                            <>
                                <img src={separator}></img>
                                <a className="then-small-link" href={`${_blogLink}/${id}`} title={"quick cards"} >
                                    {title}
                                </a>
                            </>
                        ) : (null)
                    }                   
                </div>
            );
        }
        case "public_quickcards": {
            const { topic, title, card } = params;
            let _homeLink = _home;
            let _documentLink = (`/${language}/document/${id}`);
            let _quickcardsLink = (`/${language}/public/quickcards`);

            return (
                <div className="breadcrumb">
                    <a className="then-small-link" href={_homeLink} title={labels.getText()["BREADCRUMB_HOME"]()}>
                        {labels.getText()["BREADCRUMB_HOME"]()}
                    </a>
                    <img src={separator}></img>
                    <a className="then-small-link" href={`${_quickcardsLink}/list`} title={"quick cards"} >
                        quick cards
                    </a>
                    {
                        topic ? (
                            <>
                                <img src={separator}></img>
                                <a className="then-small-link" href={`${_quickcardsLink}/list/${topic}`} title={"quick cards"} >
                                    {topic}
                                </a>
                            </>
                        ) : (null)
                    }
                    {
                        title ? (
                            <>
                                <img src={separator}></img>
                                <a className="then-small-link" href={`${_quickcardsLink}/${card}`} title={"quick cards"} >
                                    {title}
                                </a>
                            </>

                        ) : (null)
                    }
                </div>
            );
        }
        case "quickcards": {
            let _homeLink = _home;
            let _documentLink = (`/${language}/document/${id}`);
            let _quickcardsLink = (`/${language}/quickcards/${id}`);

            if (professorclassroomId) {
                _homeLink = (`/${language}/professor/library/classroom/${professorclassroomId}`);
                _documentLink = (`/${language}/professor/document/classroom/${id}/${professorclassroomId}`);
                _quickcardsLink = (`/${language}/professor/quickcards/classroom/${id}/${professorclassroomId}`);
            } else if (classroomId) {
                _homeLink = (`/${language}/library/classroom/${classroomId}`);
                _documentLink = (`/${language}/document/classroom/${id}/${classroomId}`);
                _quickcardsLink = (`/${language}/quickcards/classroom/${id}/${classroomId}`);
            }

            return (
                <div className="breadcrumb">
                    <a className="then-small-link" href={_homeLink} title={labels.getText()["BREADCRUMB_HOME"]()}>
                        {labels.getText()["BREADCRUMB_HOME"]()}
                    </a>
                    <img src={separator}></img>
                    <a className="then-small-link" href={_documentLink} title={name} >
                        {ellipsis(labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name }), 25)}
                    </a>
                    <img src={separator}></img>
                    <a className="then-small-link" href={_quickcardsLink} title={"quick cards"} >
                        quick cards
                    </a>
                </div>
            );
        }
        case "quickcards_folder": {
            let _homeLink = _home;
            let _documentLink = (`/${language}/folder/${id}`);
            let _quickcardsLink = (`/${language}/folder/${id}/quickcards/`);

            if (professorclassroomId) {
                _homeLink = (`/${language}/professor/library/classroom/${professorclassroomId}`);
                _documentLink = (`/${language}/professor/document/classroom/${id}/${professorclassroomId}`);
                _quickcardsLink = (`/${language}/professor/quickcards/classroom/${id}/${professorclassroomId}`);
            } else if (classroomId) {
                _homeLink = (`/${language}/library/classroom/${classroomId}`);
                _documentLink = (`/${language}/document/classroom/${id}/${classroomId}`);
                _quickcardsLink = (`/${language}/quickcards/classroom/${id}/${classroomId}`);
            }

            return (
                <div className="breadcrumb">
                    <a className="then-small-link" href={_homeLink} title={labels.getText()["BREADCRUMB_HOME"]()}>
                        {labels.getText()["BREADCRUMB_HOME"]()}
                    </a>
                    <img src={separator}></img>
                    <a className="then-small-link" href={_documentLink} title={name} >
                        {ellipsis(labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name }), 25)}
                    </a>
                    <img src={separator}></img>
                    <a className="then-small-link" href={_quickcardsLink} title={"quick cards"} >
                        quick cards
                    </a>
                </div>
            );
        }
        case "classrooms": {
            return (
                <span className="crumb1">
                    <a href={_home} title={labels.getText()["BREADCRUMB_CLASSROOMS"]()}>
                        {labels.getText()["BREADCRUMB_HOME"]()}
                    </a>
                    <img src={separator}></img>
                    <a href={`/${language}/classRoom/${id}`} title={name} >
                        {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                    </a>
                </span>
            );
        }
        case "q&a":
            {
                let _homeLink = _home;
                let _documentLink = (`/${language}/document/${id}`);
                let _qaLink = (`/${language}/qa/${id}`);

                if (professorclassroomId) {
                    _homeLink = (`/${language}/professor/library/classroom/${professorclassroomId}`);
                    _documentLink = (`/${language}/professor/document/classroom/${id}/${professorclassroomId}`);
                    _qaLink = (`/${language}/professor/qa/${id}/${professorclassroomId}`);
                } else if (classroomId) {
                    _homeLink = (`/${language}/library/classroom/${classroomId}`);
                    _documentLink = (`/${language}/document/classroom/${id}/${classroomId}`);
                    _qaLink = (`/${language}/classroom/qa/${id}/${classroomId}`);

                }

                return (
                    <span className="breadcrumb">
                        <a className="then-small-link" href={_homeLink} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            {labels.getText()["BREADCRUMB_HOME"]()}                        </a>
                        <img src={separator}></img>
                        <a className="then-small-link" href={_documentLink} title={name}>
                            {ellipsis(labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name }), 25)}
                        </a>
                        <img src={separator}></img>
                        <a className="then-small-link" href={_qaLink} title={labels.getText()["BREADCRUMB_QA"]()} >
                            {labels.getText()["BREADCRUMB_QA"]()}
                        </a>
                    </span>
                );
            }
        case "q&a_folder":
            {
                const { sharedMode } = params;

                if (professorclassroomId) {
                    return (
                        <span className="crumb2">
                            <a href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                                {labels.getText()["BREADCRUMB_HOME"]()}
                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/professor/classrooms`} title={labels.getText()["MANAGE_YOUR_CLASS"]()}>
                                {labels.getText()["MANAGE_YOUR_CLASS"]()}
                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/professor/classroom/${professorclassroomId}`} title={classroomName}>
                                {classroomName}
                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/professor/library/classroom/${professorclassroomId}`} title={labels.getText()["CLASSROOM_LIBRARY"]()}>
                                {labels.getText()["CLASSROOM_LIBRARY"]()}
                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/professor/folder/classroom/${folderId}/${professorclassroomId}`} title={name}>
                                {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/professor/qa/folder/${folderId}/${professorclassroomId}`} title={name}>
                                {labels.getText()["BREADCRUMB_QA"]({ name: name })}
                            </a>
                        </span>

                    );
                } else if (classroomId) {
                    return (
                        <span className="breadcrumb">
                            <a className="then-small-link" href={`/${language}/library/classroom/${classroomId}`} title={labels.getText()["BREADCRUMB_HOME"]()}>
                                {labels.getText()["BREADCRUMB_HOME"]()}
                            </a>
                            <img src={separator}></img>
                            <a className="then-small-link" href={`/${language}/folder/classroom/${folderId}/${classroomId}`} title={name}>
                                {ellipsis(labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name }), 25)}
                            </a>
                            <img src={separator}></img>
                            <a className="then-small-link" href={`/${language}/classroom/qa/folder/${folderId}/${classroomId}`} title={labels.getText()["BREADCRUMB_QA"]()} >
                                {labels.getText()["BREADCRUMB_QA"]()}
                            </a>

                        </span>)
                } else {
                    return (
                        <span className="breadcrumb">
                            <a className="then-small-link" href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                                {labels.getText()["BREADCRUMB_HOME"]()}
                            </a>
                            <img src={separator}></img>
                            <a className="then-small-link" href={sharedMode ? _home : `/${language}/folder/${id}`} title={name}>
                                {ellipsis(labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name }), 25)}
                            </a>
                            <img src={separator}></img>
                            <a className="then-small-link" href={`/${language}/folder/${id}/qa`} title={labels.getText()["BREADCRUMB_QA"]()} >
                                {labels.getText()["BREADCRUMB_QA"]()}
                            </a>
                        </span>
                    );
                }
            }
        case "training":
            {
                let _homeLink = _home;
                let _documentLink = (`/${language}/document/${id}`);
                let _trainingLink = (`/${language}/training/${id}`);

                if (professorclassroomId) {
                    _homeLink = (`/${language}/professor/library/classroom/${professorclassroomId}`);
                    _documentLink = (`/${language}/professor/document/classroom/${id}/${professorclassroomId}`);
                    _trainingLink = (`/${language}/professor/training/${id}/${professorclassroomId}`);
                } else if (classroomId) {
                    _homeLink = (`/${language}/library/classroom/${classroomId}`);
                    _documentLink = (`/${language}/document/classroom/${id}/${classroomId}`);
                    _trainingLink = (`/${language}/classroom/training/${id}/${classroomId}`);

                }

                return (
                    <span className="breadcrumb">
                        <a href={_homeLink} className="then-small-link" title={labels.getText()["BREADCRUMB_HOME"]()}>
                            {labels.getText()["BREADCRUMB_HOME"]()}                        </a>
                        <img src={separator}></img>
                        <a href={_documentLink} className="then-small-link" title={name}>
                            {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                        </a>
                        <img src={separator}></img>
                        <a href={_trainingLink} className="then-small-link" title={labels.getText()["BREADCRUMB_TRAINING"]()} >
                            {labels.getText()["BREADCRUMB_TRAINING"]()}
                        </a>
                    </span>
                );
            }
        case "training_folder":
            {

                if (professorclassroomId) {
                    return (
                        <span className="breadcrumb">
                            <a href={_home} className="then-small-link" title={labels.getText()["BREADCRUMB_HOME"]()}>
                                {labels.getText()["BREADCRUMB_HOME"]()}                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/professor/classrooms`} className="then-small-link" title={labels.getText()["MANAGE_YOUR_CLASS"]()}>
                                {labels.getText()["MANAGE_YOUR_CLASS"]()}
                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/professor/classroom/${professorclassroomId}`} className="then-small-link" title={classroomName}>
                                {classroomName}
                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/professor/library/classroom/${professorclassroomId}`} className="then-small-link" title={labels.getText()["CLASSROOM_LIBRARY"]()}>
                                {labels.getText()["CLASSROOM_LIBRARY"]()}
                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/professor/folder/classroom/${folderId}/${professorclassroomId}`} className="then-small-link" title={name}>
                                {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/professor/folder/${folderId}/${professorclassroomId}/training`} className="then-small-link" title={labels.getText()["BREADCRUMB_TRAINING"]()} >
                                {labels.getText()["BREADCRUMB_TRAINING"]({ name: name })}
                            </a>
                        </span>

                    );
                } else if (classroomId) {
                    return (
                        <span className="breadcrumb">
                            <a href={`/${language}/library/classroom/${classroomId}`} className="then-small-link" title={labels.getText()["BREADCRUMB_HOME"]()}>
                                {labels.getText()["BREADCRUMB_HOME"]()}                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/folder/classroom/${folderId}/${classroomId}`} className="then-small-link" title={name}>
                                {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/classroom/folder/${folderId}/${classroomId}/training`} className="then-small-link" title={labels.getText()["BREADCRUMB_TRAINING"]()} >
                                {labels.getText()["BREADCRUMB_TRAINING"]()}
                            </a>
                        </span>
                    );
                } else {
                    return (
                        <span className="breadcrumb">
                            <a href={_home} className="then-small-link" title={labels.getText()["BREADCRUMB_HOME"]()}>
                                {labels.getText()["BREADCRUMB_HOME"]()}                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/folder/${folderId}`} className="then-small-link" title={name}>
                                {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                            </a>
                            <img src={separator}></img>
                            <a href={`/${language}/folder/${folderId}/training/`} className="then-small-link" title={labels.getText()["BREADCRUMB_TRAINING"]()} >
                                {labels.getText()["BREADCRUMB_TRAINING"]()}
                            </a>
                        </span>
                    );
                }

            }
        case "survey":
            {
                let _homeLink = _home;
                let _documentLink = (`/${language}/document/${id}`);
                let _trainingLink = (`/${language}/training/${id}`);
                let _survey = (`/${language}/survey/${topicId}/${id}`);


                if (professorclassroomId) {
                    _homeLink = (`/${language}/professor/library/classroom/${professorclassroomId}`);
                    _documentLink = (`/${language}/professor/document/classroom/${id}/${professorclassroomId}`);
                    _trainingLink = (`/${language}/professor/training/${id}/${professorclassroomId}`);
                    _survey = (`/${language}/professor/survey/${topicId}/${id}/${professorclassroomId}`);

                }
                else if (classroomId) {
                    _homeLink = (`/${language}/library/classroom/${classroomId}`);
                    _documentLink = (`/${language}/document/classroom/${id}/${classroomId}`);
                    _trainingLink = (`/${language}/classroom/training/${id}/${classroomId}`);
                    _survey = (`/${language}/classroom/survey/${topicId}/${id}/${classroomId}`);

                }


                return (
                    <span className="crumb1">
                        <a href={`${_homeLink}`} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            <i className="fa fa-home" aria-hidden="true"></i>
                        </a>
                        <img src={separator}></img>
                        <a href={`${_documentLink}`} title={name}>
                            {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                        </a>
                        <img src={separator}></img>
                        <a href={`${_trainingLink}`} title={labels.getText()["BREADCRUMB_TRAINING"]()}>
                            {labels.getText()["BREADCRUMB_TRAINING"]()}
                        </a>
                        <img src={separator}></img>
                        <a href={`${_survey}`} title={labels.getText()["BREADCRUMB_SURVEY"]()} >
                            {labels.getText()["BREADCRUMB_SURVEY_ID"]({ id: id2 })}
                        </a>
                    </span>
                );
            }
        case "survey_folder":
            {

                return (
                    <span className="crumb1">
                        <a href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            <i className="fa fa-home" aria-hidden="true"></i>
                        </a>
                        <img src={separator}></img>
                        <a href={`/${language}/folder/${folderId}`} title={name}>
                            {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                        </a>
                        <img src={separator}></img>
                        <a href={`/${language}/folder/${folderId}/training`} title={labels.getText()["BREADCRUMB_TRAINING"]()}>
                            {labels.getText()["BREADCRUMB_TRAINING"]()}
                        </a>
                        <img src={separator}></img>
                        <a href={`/${language}/survey/folder/${packageId}/${folderId}`} title={labels.getText()["BREADCRUMB_SURVEY"]()} >
                            {labels.getText()["BREADCRUMB_SURVEY_ID"]({ id: packageId })}
                        </a>
                    </span>
                );
            }
        case "mindmap":
            {
                return (
                    <span className="crumb2">
                        <a href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            <i className="fa fa-home" aria-hidden="true"></i>
                        </a>
                        <img src={separator}></img>
                        <a href={`/${language}/document/${id}`} title={name}>
                            {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                        </a>
                        <img src={separator}></img>
                        <a href={`/${language}/mindmap/${id}/`} title={labels.getText()["BREADCRUMB_MINDMAP"]()}  >
                            {labels.getText()["BREADCRUMB_MINDMAP"]()}
                        </a>
                    </span>
                );
            }
        case "submindmap":
            {
                return (
                    <span className="crumb2">
                        <a href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            <i className="fa fa-home" aria-hidden="true"></i>
                        </a>
                        <img src={separator}></img>
                        <a href={`/${language}/document/${id}`} title={name}>
                            {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                        </a>
                        <img src={separator}></img>
                        <a href={`/${language}/mindmap/${id2}/`} title={labels.getText()["BREADCRUMB_MINDMAP"]()}  >
                            {labels.getText()["BREADCRUMB_MINDMAP"]()}
                        </a>
                        <img src={separator}></img>
                        <a href={`/${language}/mindmap/${id2}/${topicId}`} title={labels.getText()["BREADCRUMB_SUBMINDMAP"]({ topic: topicName })}  >
                            {labels.getText()["BREADCRUMB_SUBMINDMAP"]({ topic: topicName })}
                        </a>
                    </span>
                );
            }
        case "folder": {

            if (professorclassroomId) {

                return (
                    <div className="breadcrumb">
                        <a className="then-small-link" href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            {labels.getText()["BREADCRUMB_HOME"]()}
                        </a>

                        <img src={separator}></img>
                        <a className="then-small-link" href={`/${language}/professor/classrooms`} title={labels.getText()["MANAGE_YOUR_CLASS"]()}>
                            {labels.getText()["MANAGE_YOUR_CLASS"]()}
                        </a>
                        <img src={separator}></img>
                        <a className="then-small-link" href={`/${language}/professor/classroom/${professorclassroomId}`} title={classroomName}>
                            {classroomName}
                        </a>
                        <img src={separator}></img>
                        <a className="then-small-link" href={`/${language}/professor/library/classroom/${professorclassroomId}`} title={labels.getText()["CLASSROOM_LIBRARY"]()}>
                            {labels.getText()["CLASSROOM_LIBRARY"]()}
                        </a>
                        <img className="then-small-link" src={separator}></img>
                        <a className="then-small-link" href={`/${language}/professor/folder/classroom/${folderId}/${professorclassroomId}`} title={name}>
                            {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                        </a>
                    </div>

                );

            } else if (classroomId) {
                return (
                    <div className="breadcrumb">
                        <a className="then-small-link" href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            {labels.getText()["BREADCRUMB_HOME"]()}
                        </a>
                        <img src={separator}></img>
                        <a className="then-small-link" href={`/${language}/folder/classroom/${folderId}/${classroomId}`} title={name}>
                            {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                        </a>
                    </div>

                );
            }
            else {
                return (
                    <div className="breadcrumb">
                        <a className="then-small-link" href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            {labels.getText()["BREADCRUMB_HOME"]()}
                        </a>
                        <img src={separator}></img>
                        <a className="then-small-link" href={`/${language}/folder/${folderId}`} title={name}>
                            {labels.getText()["BREADCRUMB_DOCUMENT"]({ name: name })}
                        </a>
                    </div>

                );
            }

        }
        case "classRoomScreen":
            {
                return (
                    <div className="breadcrumb">
                        <a className="then-small-link" href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            {labels.getText()["BREADCRUMB_HOME"]()}
                        </a>
                        <img src={separator}></img>
                        <a className="then-small-link" href={`//${language}/classrooms`} title={name}>
                            {labels.getText()["MANAGE_YOUR_CLASS"]()}
                        </a>
                    </div>

                );
            }
        case "class_room_management":
            {
                return (
                    <div className="breadcrumb">
                        <a className="then-small-link" href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            {labels.getText()["BREADCRUMB_HOME"]()}
                        </a>
                        <img src={separator}></img>
                        <a className="then-small-link" href={`/${language}/professor/classrooms`} title={name}>
                            {labels.getText()["MANAGE_YOUR_CLASS"]()}
                        </a>
                        <img src={separator}></img>
                        <a className="then-small-link" href={`/${language}/professor/classroom/${professorclassroomId}`} title={name}>
                            {classroomName}
                        </a>
                    </div>

                );
            }

        case "user_profile":
            {
                return (
                    <div className="breadcrumb">
                        <a className="then-small-link" href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            {labels.getText()["BREADCRUMB_HOME"]()}
                        </a>
                        <img src={separator}></img>
                        <a className="then-small-link" href={`/${language}/user_profile`} title={name}>
                            {labels.getText()["ACCOUNT_DETAILS"]()}
                        </a>

                    </div>

                );
            }

        case "user_subscription_profile":
            {
                return (
                    <div className="breadcrumb">
                        <a className="then-small-link" href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            {labels.getText()["BREADCRUMB_HOME"]()}
                        </a>
                        <img src={separator}></img>
                        <a className="then-small-link" href={`/${language}/user_subscription`} title={name}>
                            {labels.getText()["SUBSCRIPTION_SETTINGS"]()}
                        </a>

                    </div>

                );
            }

        case "user_payment_profile":
            {
                return (
                    <div className="breadcrumb">
                        <a className="then-small-link" href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                            {labels.getText()["BREADCRUMB_HOME"]()}
                        </a>
                        <img src={separator}></img>
                        <a className="then-small-link" href={`/${language}/user_payments`} title={name}>
                            {labels.getText()["PAYMENT_DETAILS"]()}
                        </a>

                    </div>

                );
            }

        case "blogListManager": {

            return (
                <div className="breadcrumb">
                    <a className="then-small-link" href={_home} title={labels.getText()["BREADCRUMB_HOME"]()}>
                        {labels.getText()["BREADCRUMB_HOME"]()}
                    </a>
                    <img src={separator}></img>
                    <a className="then-small-link" href={`/${language}/blogmanager`} title={name}>
                        Blog list manager
                    </a>

                </div>

            );

        }

        default:
            {

            }
            return;
    }
}

function SetMenu(params) {
    const language = labels.getLanguage();
    const upperCaseLanguage = language.toUpperCase();
    window.$('#languageModalLabel').text(labels.getText()['LANGUAGE_MODAL_LABEL']());
    const { user = null, section } = params;

    if (user) {
        labels.getText()['MENU_USER_LOGGED']({ upperCaseLanguage: upperCaseLanguage, user: user, section });
        // labels.getText()['FOOTER_POPULAR_LINKS_LOGGED']();
        labels.getText()['FOOTER_BOTTOM_MENU_LOGGED']();
    }
    else if (!user) {
        labels.getText()['MENU_USER_NOT_LOGGED']({ upperCaseLanguage: upperCaseLanguage, section });
        // labels.getText()['FOOTER_POPULAR_LINKS_NOT_LOGGED']();
        labels.getText()['FOOTER_BOTTOM_MENU_NOT_LOGGED']();
    }

    // labels.getText()['FOOTER_SERVICES']();
    // labels.getText()['GET_IN_TOUCH_FOOTER']();

    SetPopularLinks(params);
    SetServices(params);
    SetFooterBottom(params);
}

function SetPopularLinks(params) {
    const language = labels.getLanguage();
    const upperCaseLanguage = language.toUpperCase();
    const { user = null } = params;

    // if (user) {
    //     labels.getText()['FOOTER_POPULAR_LINKS_LOGGED']();
    // }
    // else if (!user) {
    //     labels.getText()['FOOTER_POPULAR_LINKS_NOT_LOGGED']();
    // }
}

function SetServices(params) {
    const language = labels.getLanguage();
    const upperCaseLanguage = language.toUpperCase();
    // labels.getText()['FOOTER_SERVICES']();
    // labels.getText()['GET_IN_TOUCH_FOOTER']();
}

function SetFooterBottom(params) {
    const language = labels.getLanguage();
    const upperCaseLanguage = language.toUpperCase();
    const { user = null } = params;
    if (user) {
        labels.getText()['FOOTER_BOTTOM_MENU_LOGGED']();
    }
    else if (!user) {
        labels.getText()['FOOTER_BOTTOM_MENU_NOT_LOGGED']();

    }
}

export { getBreadcrumb, SetMenu };
// , SetPopularLinks, SetServices, SetFooterBottom
